.ui.modal.modal-view {
  .content,
  .container.content-wrapper {
    padding: 0;
    border: none;
  }

  .table-card-view {
    margin-top: 1rem;
  }

  .modal-view-container {
    .header {
      position: relative;
      font-size: 1.2rem;
      font-weight: 500;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      font-size: 1.3rem;
      font-weight: 600;
      padding: 1rem 1.8rem;
      width: 100%;
      border-bottom: 1px solid var(--color-mystic);

      .btn-text.close {
        position: absolute;
        top: 1rem;
        right: 2rem;
        font-size: 1.25rem;
      }
    }

    .body {
      padding: 0 2.2rem;
      font-size: 1rem;
      margin-top: 1.2rem;
    }

    .footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid var(--color-mystic);
      padding: 1.1rem;
      margin-top: 2rem;

      .btn {
        border: 1px solid var(--color-mystic);
        border-radius: 4px;
        padding: 0.5rem 1.1rem;
        font-size: 1rem;
        font-weight: 500;
        color: var(--lb-color-font-primary);

        &:not(:last-of-type) {
          margin-right: 1rem;
        }

        &.confirm {
          color: var(--color-white);
          background-color: var(--color-primary-light);
        }

        &:disabled {
          opacity: 1;
        }
      }
    }
  }
}
