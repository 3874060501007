header.ui.header.page-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin: 0;

  h1 {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.3rem;
    white-space: pre;
  }

  .subheader {
    order: -1;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .subheader .header-icon {
    margin-right: 10px;
    width: 1rem;
    height: 1rem;
  }
}
