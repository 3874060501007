@import '../../assets/mixins.less';

.access-denied {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .denied-content {
    text-align: center;
    p {
      margin-bottom: 6px;
      &.error-code {
        font-weight: 400;
        font-size: 76.6667px;
        line-height: 93px;
        color: #7f8ced;
      }
      &.error-title {
        .font-medium(1.7rem, 1.6em);
        color: var(--color-river-bed);
      }
      &.error-desc {
        .font-medium(1.15rem, 1.75em);
        color: var(--color-text-secondary);
      }
    }
  }
}
