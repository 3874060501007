.ui.form .inline.fields .field {
  flex-grow: 1;
}

.elastic-search-settings {
  .ui.form.register-form {
    .form-wrapper {
      width: auto;
      max-width: none;
    }
  }
  .step2-dropdown {
    .ui.label {
      background-color: white;
      color: var(--lb-color-font-primary);
    }

    .field {
      width: 50%;
    }
  }
}
