.items-area {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 100%;
  min-height: 10rem;
  border-radius: 6px;
  border: 1px solid #d1d5da;
  padding: 0.8rem 0.9rem;
  margin-top: 1rem;

  &.disabled {
    background-color: #fafbfc;
  }

  .item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0.2rem 0.4rem;
    color: var(--color-text-third);
    font-size: 0.86rem;
    font-weight: 500;
    background-color: #eff4fa;
    box-shadow: 0px 2px 2px #d7dde2;
    border-radius: 2px;
    margin-right: 1rem;
    margin-bottom: 1rem;

    .icon-box {
      margin-left: 0.4rem;
    }

    &.add-new {
      .icon-box {
        margin-left: 0;
        margin-right: 0.4rem;
      }
      background: none;
    }
  }
}

.items-area-popup {
  top: 1rem;
  left: -1rem;
  padding: 0 !important;
  color: var(--color-text-third);
  background-color: var(--color-white);
  border: 1px solid #bac4ff !important;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px;
  z-index: 9999 !important;

  .ui.input input {
    font-size: 0.86rem;
    border: none;

    &::placeholder {
      font-size: 0.86rem;
    }
  }

  &::before {
    display: none;
  }
}
