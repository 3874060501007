@import '../../assets/mixins';

.menu-right {
  display: flex;
  align-items: center;

  .search-container.global-search {
    flex-grow: 1;
    padding-left: 1.6rem;
  }
  .ui.secondary.menu {
    justify-content: flex-end;
    .item {
      padding: 0 0.5rem;
      cursor: pointer;
      .menu {
        border: 1px solid #d1d5da;
        box-shadow: 0px 0.6px 1.8px rgba(36, 41, 46, 0.1), 0px 3.2px 7.2px rgba(36, 41, 46, 0.13);
        border-radius: 4px;
        .header {
          border-bottom: 1px solid #dfe3e8;
          & > p {
            .font-bold(0.86rem, 1.5em);
            text-transform: uppercase;
            color: #7d8791;
          }
          .header-content {
            display: flex;
            align-items: center;
            div {
              .user-email {
                .font-medium(0.86rem, 1.5em);
                color: #7d8791;
                text-transform: lowercase;
              }
              .user-name {
                .font-medium(1rem, 1.28em);
                text-transform: capitalize;
                color: #1b2229;
              }
            }
          }
        }
        .item {
          font-weight: 500 !important;
          font-size: 1rem !important;
          line-height: 1.28em;
          color: #1b2229 !important;
          padding: 1.15rem 1.7rem !important;
          &:hover {
            background: #f5f7ff !important;
          }
        }
      }
    }
  }
}
