.annotation-feedback-container {
  padding: 0 4rem;

  .section {
    margin-top: 3rem;

    .title {
      font-weight: 600;
    }

    .title,
    .ui.checkbox label {
      font-size: 1.15rem;
    }
  }

  .section.checkbox {
    .checkbox-container {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      gap: 1rem;
      place-items: start start;
      font-size: 1.25rem;
    }
  }

  .section.textarea {
    .editable {
      margin-top: 1rem;
      border: 1px solid var(--color-inputs-border-default);
      background-color: var(--color-white);
      border-radius: 4px;
      padding: 1.14em;
      color: var(--color-non-click-text);
      min-height: 8rem;
      font-weight: 400;
      &:focus {
        outline: none;
      }
    }

    .note {
      margin-top: 0.85rem;
      font-size: 1rem;
    }
  }
}
