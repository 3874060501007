@import '../../assets/mixins.less';

.percent-indicator {
  .font-medium(0.8rem,1.1em);
  display: inline-flex;
  padding-top: 1.35rem;
  &.percent-increase {
    color: #3bb254;
  }
  &.percent-decrease {
    color: #f04647;
  }
  .triangle-down {
    border-top: 0.86rem solid #f04647;
  }
  .triangle-up {
    border-bottom: 0.86rem solid #3bb254;
  }
  .triangle {
    width: 0;
    height: 0;
    margin-top: 2px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
  &.indicator-small {
    .triangle {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    .triangle-down {
      border-top: 0.6rem solid #f04647;
    }
    .triangle-up {
      border-bottom: 0.6rem solid #3bb254;
    }
  }
}
