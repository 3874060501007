.ui.visible.dimmer.custom-loader {
  position: absolute;
  margin: 0;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.ui.visible.dimmer .lb-loader {
  width: 17vw;
  max-width: 10rem;
}

.body-loader {
  z-index: 9999 !important;
  .content {
    position: relative;
    top: 50%;
  }
}

.custom-table-list-loader-container {
  position: absolute;
  width: 100%;
  height: 100%;

  .ui.visible.dimmer.custom-table-list-loader {
    .content {
      position: absolute;
      top: 0;
    }
  }
}
