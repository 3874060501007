@import '../../assets/mixins.less';

.trend-widget {
  .list-trend {
    display: flex;
    border-top: 1px solid #ccc;
    .list-item-trend {
      border-left: 1px solid #ccc;
      flex: 1 0 0;
      position: relative;
      .item-count {
        .font-medium(2.42rem,1.17em);
        color: #0b1354;
        display: flex;
      }
      .item-text {
        .font-normal(1rem,1.14em);
        display: flex;
        align-items: center;
        .bullet {
          margin-right: 6px;
          width: 0.72rem;
          height: 0.72rem;
        }
      }
      &:first-child .item-count {
        color: #6941c6;
      }
      &:before {
        content: '';
        display: block;
        background-color: #ccc;
        border-radius: 50%;
        position: absolute;
        top: -0.35rem;
        left: -0.35rem;
        padding: 0.35rem;
      }
    }
  }
  .widget-title {
    .font-medium(1rem,1.21em);
    color: #262626;
    text-transform: uppercase;
  }
}
