@import '../../../../assets/mixins';

.register-or-update-status {
  .register-or-update {
    display: flex;
    align-items: center;
    padding-left: 1.25rem;
  }

  .ui.basic.button.btn-register-or-update {
    padding: 0.5em 1.43em !important;
    color: var(--color-non-click-text) !important;
    border-radius: 4px !important;
    border: 1px solid var(--color-inputs-border-default);
    box-shadow: none;
    white-space: nowrap;
    .font-normal(1rem, 20px);
  }

  .status-message {
    white-space: nowrap;
    .font-normal(1rem, 20px);

    &.error {
      color: var(--color-error);
    }

    &.success {
      color: var(--color-success);
    }
  }
}
