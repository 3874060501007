@import '../../assets/mixins.less';

.attributes {
  position: relative;

  .controls {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  .buttons-container {
    margin-bottom: 1rem;
  }

  .buttons-container {
    display: flex;
  }

  .search-wrapper {
    flex-grow: 1;

    .ui.input {
      input {
        border-radius: 6px;
      }
    }
  }

  .attributes-header {
    .font-semiBold(1rem, 1.43em);
  }

  .cards-list {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-auto-rows: 1fr;
    gap: 1.1rem;

    .card {
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      border: 1px solid var(--color-mystic);
      background-color: var(--color-attributes-card-bg);
      padding: 1.8rem;
      border-radius: 6px;

      &:hover {
        filter: drop-shadow(0px 4px 4px #d7dde2);
      }

      .icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background-color: var(--color-attributes-card-icon-bg);
        border-radius: 50%;
        width: 3.7rem;
        height: 3.7rem;
      }

      .content-box {
        margin-left: 1rem;
        flex-grow: 1;
        overflow: hidden;

        .name {
          font-size: 1.15rem;
          font-weight: 600;
          color: var(--color-entities-title);
          text-transform: uppercase;
        }
        .attributes-count {
          font-size: 0.86rem;
          color: var(--color-attributes-secondary);
        }

        .objects-count {
          margin-top: 1.2rem;
          color: var(--color-primary-light);
          font-size: 1.3rem;
          font-weight: 600;
        }

        .objects-count-text {
          margin-top: 0.5rem;
          font-size: 0.86rem;
          color: var(--color-text-secondary);
          text-transform: uppercase;
          font-weight: 500;
        }

        .separator {
          margin: 1rem 0;
          height: 1px;
          width: 100%;
          background-color: var(--color-mystic);
        }

        .data-sources-count {
          font-size: 0.72rem;
          color: var(--color-attributes-grey);
        }

        .data-sources {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          img {
            max-width: 1.3rem;
            max-height: 1.3rem;
            margin-right: 0.8rem;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  .attributes-list {
    border: 1px solid var(--color-mystic);
    border-radius: 8px;
    padding: 1.8rem;
  }

  .table-card-view .ui.basic.table,
  .table-card-view .ui.basic.table.sortable {
    thead th:first-of-type.mail-info {
      width: 40%;
    }
  }
}

.attributes.mail {
  .info-expanded {
    display: flex;
    padding: 1rem 0;
    .icon-info .no-attachments {
      display: none;
      & + svg {
        margin-top: 2px;
      }
    }
    .icon-info {
      display: flex;
      flex-direction: column;
    }
  }
  .field-info.attachments {
    .field-value {
      flex-wrap: wrap;
      & > span {
        margin-bottom: 1rem;
      }
      .more-items {
        margin-bottom: 1rem !important;
      }
    }
  }
}
/** Top 3 classifications widget **/
.attribute-dashboard-widget-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.attributes.widget-grouped {
  flex-grow: 1;
  display: flex;
  min-width: 13rem;
  position: relative;
  margin-top: 0;
  height: 100%;
  gap: 1rem;

  .empty-legend {
    width: 100%;
  }

  &.vertical {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .legend {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;

      .item {
        margin-right: 0.5rem;
      }
    }
  }
  &.horizontal {
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;

    .legend {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.28rem;

      .item {
        line-height: 1.2em;
        margin: 0;
      }

      .key {
        width: 6rem;
        margin-right: 1rem;
      }
      .value {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
      }
    }
  }

  .empty-state-text {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
  }

  .chart-text {
    text-align: center;
    margin-top: -1rem;
    font-size: 0.86rem;
  }

  .recharts-wrapper svg {
    overflow: visible;
  }

  .icon-box {
    display: flex;
  }

  .legend {
    .legend-horizontal-title {
      font-weight: 600;
      font-size: 0.86rem;
    }
    .item {
      display: flex;
      align-items: center;
      color: var(--color-river-bed);
      margin-top: 0.5rem;

      .circle {
        flex-shrink: 0;
        flex-grow: 0;
        width: 0.61rem;
        height: 0.61rem;
        margin-right: 0.3rem;
        border-radius: 50%;
      }
    }
  }
}
.more-attr-sets-info {
  display: flex;
  .ui.button {
    font-size: 0.86rem;
    font-weight: bold;
  }
}

@media screen and (min-width: 650px) {
  .attributes .cards-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (min-width: 1200px) {
  .attributes .cards-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media screen and (min-width: 1440px) and (max-width: 1549px) {
  .attributes.widget-grouped.horizontal .legend {
    .key {
      width: 5rem;
    }
  }
}

@media screen and (min-width: 1550px) {
  .attributes .cards-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
@media screen and (min-width: 1850px) {
  .attributes .cards-list {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
@media screen and (min-width: 2100px) {
  .attributes .cards-list {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
