.print-page.page-title,
.print-page.page-final {
  display: flex;
  flex-flow: column nowrap;
  .wrapper {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
  }
}
.widget-title-uppercase {
  text-transform: uppercase;
}
.delta-print-page {
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  .ui.table thead .delta-header-cell-bg {
    background: #f9fafb !important;
    color: #24292e !important;
  }
  .cards-list {
    .percent-indicator {
      padding-top: 0;
    }
  }
  .datasource-overview {
    display: flex;
    flex-flow: column nowrap;
    padding: 2rem 4rem;
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > .ui.label {
        display: inline-flex;
      }
    }
    .ds-name-files {
      display: inline-flex;
      align-items: center;
      img {
        max-width: 2rem;
        max-height: 2rem;
        height: auto;
      }
      .title {
        font-weight: 600;
        font-size: 1rem;
      }
    }
    .bold {
      font-weight: 600;
    }
    .table-trend-widget {
      table {
        width: 100%;
      }
    }
    .entites-overview {
      border: 2px solid #ececec;
      margin: 2rem 0;
      width: 40%;
      border-radius: 1rem;
      padding: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .section-1 {
        border-right: 2px solid #ececec;
      }
      .sections {
        flex: 1;
        text-align: center;
        .text-count {
          font-size: 2rem;
        }
      }
    }
    .top-5-widgets {
      display: grid;
      gap: 1.75rem;
      grid-auto-rows: minmax(240px, auto);
      grid-template-columns: 1fr 1fr;
    }
  }

  .chart-bar-simple {
    background-color: #fff;
    .recharts-responsive-container {
      margin-left: -3rem;
    }
  }
  .attribute-counts {
    font-weight: 400;
    font-size: 0.8rem;
    margin-left: 2rem;
  }
  .attribute-bold {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2rem;
    margin-right: 8px;
    vertical-align: middle;
    color: #0b1354;
  }
  .chart-area-simple {
    background-color: #fff;
  }
  &.attribute-page {
    padding: 2rem;
  }
  .border {
    border-bottom: 2px solid #ececec;
    padding-bottom: 2rem;
  }
  .widget-title {
    font-size: 1rem;
    line-height: 1.21em;
    font-weight: 500;
    color: #262626;
    &.overview-title {
      margin: 2rem 4rem -1rem 4rem;
      font-weight: 400;
    }
  }
  .bold-text {
    font-weight: 600;
  }
  .text-tag {
    background-color: #e9edf1;
    width: 20%;
    font-size: 12px;
    padding: 5px 10px;
  }
  .bottom-summary {
    background-color: #f1f8fe;
    font-size: 14px;
    width: 100%;
    border-radius: 7px;
    padding: 10px 40px;
    font-weight: 500;
  }
  .ui.table {
    border: 02px solid #ececec;
    border-collapse: collapse;
    width: 95%;
    tr {
      border-bottom: 1px solid #d5dee8;
      td,
      th {
        border: 0;
        padding: 10px 18px;
      }
      &,
      th,
      td {
        box-shadow: none !important;
        border-radius: 0 !important;
        border-radius: 0 !important;
      }
    }
    thead {
      background-color: #f6f8fa !important;
      th {
        background-color: transparent;
        font-weight: 500;
        font-size: 1.15rem;
        line-height: 20px;
      }
    }

    tbody {
      tr {
        td {
          font-weight: 500;
          font-size: 1rem;
          line-height: 20px;
          color: #000000;
          &.field-name {
            color: var(--color-text-secondary) !important;
            .description-title {
              font-weight: 400;
              font-size: 0.85rem;
              line-height: 14px;
              color: #959da5;
            }

            &.title {
              font-weight: 500 !important;
              font-size: 1.1rem !important;
              color: var(--color-black) !important;
            }
          }
          &.capitalize {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  figure {
    margin: 0;
    padding: 0;
  }

  .wrapper {
    .table-legend-light {
      font-weight: 400;
      font-size: 1.35rem;
      line-height: 1.3em;
      color: var(--color-text-secondary);
      align-self: flex-start;
      span {
        color: var(--color-emoby-clay);
        font-weight: 700;
        font-size: 0.95em;
      }
    }
    .help-box {
      align-self: flex-start;
      min-width: 100%;
      .help-legend {
        font-weight: 500;
        font-size: 1.35rem;
        line-height: 1.7em;
        color: var(--color-river-bed);
      }
      .help-text {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.4em;
        color: var(--color-emoby-clay);
        background: #eff5ff;
        border-radius: 8px;
        p {
          max-width: 55%;
        }
      }
    }
  }

  .wrapper,
  .header {
    padding: 2rem 4rem;
  }
  .wrapper-cover {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  .header {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    height: 7rem;
    background: linear-gradient(89.97deg, #101175 10.18%, #3633a0 48.78%, #3629ca 89.3%);
    color: var(--color-white);

    .sub-text {
      font-size: 0.72rem;
      margin-bottom: 1rem;
    }

    .text {
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  .footer {
    display: flex;
    flex-flow: nowrap;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    flex-direction: column !important;

    .divider {
      margin: 1rem 0;
      height: 1px;
      width: 100%;
      background-color: #b6bcc2;
    }

    .row {
      font-size: 0.72rem;
      width: 100%;
      justify-content: space-between;
      color: var(--color-text-secondary);
    }

    &.title-styles {
      .divider {
        background-color: var(--color-white);
      }

      .row {
        color: var(--color-white);
        font-size: 0.86rem;
      }
    }
  }

  .row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .page-break {
    page-break-before: always;
  }

  &.page-title {
    position: relative;
    background: linear-gradient(180deg, #101175 16.44%, #3633a0 57.2%, #3629ca 100%);
    display: flex;
    .icon-box {
      img {
        width: 5rem;
      }
    }

    .logo-wrapper {
      position: absolute;
      width: 12rem;
      height: 4rem;
      z-index: 1;

      .img-container {
        width: 100%;
        height: 100%;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        font-size: 0.72rem;
        color: #bac4ff;
        border: 1px solid #5d6bce;
      }
    }

    .company-wrapper {
      padding: 1rem;
      color: #bac4ff;
      position: absolute;
      top: 9rem;
      left: 3rem;
      font-size: 0.86rem;

      .text {
        border: 1px solid #5d6bce;
        padding: 0.5rem 1rem;
      }
    }

    .main-title {
      font-size: 4.5rem;
      font-weight: 700;
      line-height: 4.5rem;
      color: var(--color-white);
      margin-top: 12rem;
    }

    .sub-title {
      font-size: 1.1rem;
      font-weight: 500;
      margin-top: 1rem;
      color: var(--color-white);
    }
  }

  &.page-final {
    background: linear-gradient(180deg, #101175 16.44%, #3633a0 57.2%, #3629ca 100%);
    padding-top: 30rem;
    display: flex;
    justify-content: center;

    .logo-lb img {
      width: 8rem;
    }

    .final-text {
      margin-top: 1.5rem;
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
      color: var(--color-white);
      flex-grow: 1;
    }

    .logo-vendor,
    .final-sub-text {
      display: flex;
      justify-content: center;
    }

    .final-sub-text {
      font-size: 0.72rem;
      margin-top: 2rem;
      color: var(--color-white);
    }
  }

  &.data-sources,
  &.attribute-page {
    .risky-container {
      font-weight: 400;
      font-size: 1.3rem;
      color: var(--color-text-secondary);
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 2rem 0 1rem 0;

      .count {
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 700;
        color: var(--color-black);

        &.risky {
          color: var(--color-entities-risk);
        }
      }
    }
    .ds-content {
      padding: 2rem;
    }
    .ds-widgets-container {
      background: #e8eef3;
      border-radius: 4px;
      padding: 1rem;
      margin-bottom: 2rem;

      .ds-header {
        margin-top: 1rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        .right {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }

        .data-source-item {
          font-weight: 600;
          font-size: 1.6rem;
          color: var(--color-black);
        }

        .risk-label {
          font-size: 1rem;
          color: var(--color-white);
          background-color: var(--color-entities-risk);
          border-radius: 4px;
          padding: 0.2rem 0.4rem;
          margin-left: 1rem;
        }

        .ds-connected {
          color: var(--color-white);
          background-color: #60b05f;
          border-radius: 4px;
          padding: 0.2rem 0.4rem;
        }
      }

      .widgets-row {
        display: grid;
        grid-template-columns: repeat(2, 49.5%);
        column-gap: 1%;
        .print-widget-ds {
          padding: 2rem;
          .item {
            font-size: 0.8rem;
          }
        }
      }

      .print-widget {
        background: var(--color-white);
        border: 1px solid #d1d5da;
        border-radius: 4px;
        padding: 1rem;
        margin-top: 0.6rem;

        &.details {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding-right: 1rem;

          .left {
            margin-right: 2rem;
          }
          .right {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding-top: 1rem;
          }
          .title {
            font-weight: 500;
            font-size: 0.86rem;
            color: var(--color-black);
            text-transform: uppercase;
            margin-bottom: 1rem;
          }

          .col {
            .label {
              color: var(--color-text-secondary);
              padding: 0;
              margin: 0;
            }
          }

          .count-box {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            width: 6rem;
            flex-shrink: 0;

            & > * {
              margin-bottom: 0.5rem;
              width: 100%;
              text-align: center;
              height: 1rem;
            }

            .count {
              position: relative;
              margin-top: 0.25rem;
              font-weight: 600;
              font-size: 1.3rem;
              margin-bottom: 1.5rem;

              .icon-box {
                height: 1rem;
              }

              .count-total-wrapper {
                position: absolute;
                bottom: -1.4rem;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                width: 100%;

                .count-total {
                  background-color: #e9edf1;
                  padding: 0.1rem 0.1rem;
                  font-size: 0.72rem;
                  line-height: 0.72rem;
                  font-weight: 400;
                  color: var(--color-black);
                }
              }
            }

            .text {
              font-size: 0.72rem;
              line-height: 0.8rem;
              color: var(--color-river-bed);
            }
          }

          .divider {
            width: 1px;
            background-color: #e1e4e8;
            height: 5rem;
            margin: 0 1rem;
          }
        }
      }
    }
  }
  .recharts-legend-wrapper span {
    color: #4f4f4f;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    text-transform: capitalize;
  }
  .recharts-default-legend .recharts-legend-item {
    display: inline-flex !important;
    align-items: center;
  }
  .attributes.widget-grouped.horizontal .legend .key {
    font-size: 0.86rem;
    font-weight: 400;
  }
  .ui.label.ds-at-risk {
    border-radius: 2.028px;
    background: #eb6818;
    color: #fff;
    font-size: 12.168px;
    font-weight: 500;
    line-height: 16.224px;
  }
  .header.align-horizontal {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    .sub-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 34.68px;
      text-transform: capitalize;
      margin-bottom: 0;
      margin-top: 3rem !important;
    }
    .sub-text,
    .text {
      margin-top: 2.36rem;
    }
  }
}
.delta-contents-item {
  font-size: 17.896px !important;
  font-weight: 500;
}
.delta-submenu-item {
  font-size: 16px;
  font-weight: 400;
}

.delta-contents-item,
.delta-submenu-item {
  color: #1b2229 !important;
}
.no-data {
  flex-grow: 1;
  position: relative;
  svg {
    display: block;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    margin-right: -50%;
  }
}
.delta-page-overview {
  .wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  .cards-list {
    display: grid;
    grid-auto-rows: 1fr;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 0;

    .card {
      padding: 1.2rem;
      border: 2px solid #e1e4e8;
      border-radius: 7px;
      margin-bottom: 1.8rem;
      page-break-inside: avoid;
      .card-header {
        display: flex;
        flex-flow: row wrap;
        align-items: center !important;
        font-size: 1.1rem;
        font-weight: 500;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--color-mystic);
        align-items: baseline;
        .text {
          color: #1b2229;
        }
        .icon-box {
          margin-right: 1rem;
          background: #1ab9ff;
          border-radius: 4px;
          width: 2.85rem;
          height: 2.85rem;

          svg {
            max-width: 50%;
            max-height: 50%;
            width: auto;
            height: auto;
          }

          &.db {
            svg {
              path {
                fill: var(--color-white);
              }
            }
          }
        }

        .sub-text {
          flex-basis: 100%;
          font-size: 0.8rem;
          margin-top: -10px;
          margin-left: 2.9rem;
          color: var(--color-text-secondary);
        }
      }
      .card-footer {
        background-color: #e9edf1;
        width: 50%;
        margin-left: 2.7rem;
        font-size: 10px;
        padding: 0 10px;
        margin-top: 0.5rem;
      }
      .card-content {
        display: flex;
        flex-flow: row nowrap;
        margin-top: 2rem;
        .divider {
          border-width: 2px;
          margin-left: 5rem;
        }
        .count-item {
          .count {
            font-size: 1.6rem;
            font-weight: 600;
            color: #3f4db0;

            &.total {
              color: #444d56;
            }
            &.is-risky {
              color: #cf1322;
            }
          }

          .label {
            color: #444d56;
            font-size: 0.7rem;
            font-weight: 500;
            text-transform: uppercase;
            margin-top: 0.2rem;
            line-height: 22px;
          }
        }
      }
    }
  }
}
.page-policies.data-sources {
  .ds-name-files {
    align-items: stretch;
    flex-direction: column;
    .ds-logo-name {
      display: flex;
      margin-top: 0.5rem;
    }
  }
}

.field-name-dsr-data-type {
  color: var(--color-text-secondary);
}
.dsr-additional-comments {
  color: #959da5;
}
.dsr-data-type-bw {
  word-break: break-word;
}

.delta-report {
  .attributes-set {
    margin-left: 0;
    margin-right: 0;
  }

  // Remove clickability for DS widgets in Delta Report
  .recharts-wrapper,
  .chart-pie a,
  .attributes.widget-grouped .legend .item {
    pointer-events: none;
  }
}

@media print {
  body,
  html,
  #app {
    width: 750px !important;
  }
  .text-title {
    color: #000000de;
  }
  .report {
    .page,
    .print-page {
      page-break-after: always;
    }
  }
  .truncate {
    white-space: pre-wrap !important;
    max-width: 100% !important;
  }
  .table-card-view {
    .controls {
      display: none !important;
    }
  }
  .table-legend {
    display: none !important;
  }
  .data-sources {
    .ds-widgets-container {
      break-inside: avoid;
    }
  }
  .content-wrapper {
    border: none !important;
  }
  .mobile.only {
    display: none !important;
  }
}
.recharts-wrapper,
.recharts-surface {
  @media print {
    width: calc(100%) !important;
  }
}

.xs-mb-40 {
  margin-bottom: 40px !important;
}

.xs-my-8 {
  margin: 8px !important;
}

.ui.table {
  thead {
    background: var(--color-table-header-bg);
    border-bottom: 1px solid var(--color-table-row-separator);

    th,
    th:hover,
    th.sorted,
    th.sorted:hover {
      padding: 0.75rem 1.75rem !important;
    }
  }
}
