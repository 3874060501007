@notificationIconBackgroundColor: #f6f8fa;
@notificationNoIconBackgroundColor: #e1e4e8;

.NotificationIcon {
  width: 2.25rem;
  min-width: 2.25rem;
  height: 2.25rem;
  margin-right: 1rem;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: @notificationIconBackgroundColor;

  &:empty::after {
    content: '';
    background-color: @notificationNoIconBackgroundColor;
    width: 100%;
    height: 100%;
  }
}
