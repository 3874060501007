@import '../../assets/mixins.less';

.chart-tooltip {
  transition: 0.5s;
  transition-delay: 0.1s;
  .tooltip-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #171e25;
    font-size: 1rem;
    border-radius: 0.75em;
    padding: 0.6em 0.86rem;
    border-radius: 0.5em;
    color: var(--color-white);
    line-height: 1em;
    white-space: nowrap;
  }
}
