.data-source-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .icon-box {
    margin-right: 0.5rem;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
