@import '../../assets/mixins.less';
.nav-tabs {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 2rem;

  .nav-item {
    position: relative;
    display: flex;
    align-items: center;

    .count-container {
      font-weight: 400;
      display: flex;
      align-items: center;

      .loader {
        margin-left: 0.5rem;
      }
    }
  }
  &.button-style.inverted {
    .nav-item {
      .font-normal(0.86rem, 1.5em);
      padding: 0.86rem 1.34em;
      flex-direction: column;
      &,
      &.active {
        background-color: var(--color-white);
      }
      &.active {
        color: var(--color-purple);
        border: 1px solid var(--color-purple);
      }
      &:not(.active) {
        color: var(--color-text-secondary);
        border: 1px solid var(--color-mystic);

        .count-container {
          color: var(--color-river-bed);
        }
      }
      .count-container {
        .font-semiBold(1rem, 1.43em);
      }
      & + .nav-item {
        margin-left: 1.15rem;
      }
    }
  }
}

.nav-tabs:not(.button-style) {
  border-bottom: 1px solid #d1d5da;

  .nav-item {
    padding: 0.6rem 1.1rem;
    color: #6a737d;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: -1px;

    &:hover {
      background-color: #fafbfc;
      border-bottom: 1px solid #c0c7fc;
    }

    &.active {
      color: var(--color-purple);
      font-weight: 600;
      background-color: transparent;
      border-bottom: 2px solid var(--color-purple);

      .count-container {
        font-weight: 600;
      }
    }
  }
}

.nav-tabs.button-style {
  .nav-item {
    font-weight: 600;
    border-radius: 3px;
    padding: 0.72em 1.43em;
    &:not(.active) {
      border: 1px solid var(--color-primary-nav-border);
      color: var(--color-primary-nav-text);
    }

    &.active {
      color: var(--color-primary-nav-active-text);
      background-color: var(--color-primary-nav-active-bg);
    }
  }

  .nav-item + .nav-item {
    margin-left: 0.86em;
  }
}
