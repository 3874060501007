.databases {
  position: relative;
  .scan-all-container {
    margin-top: 1rem;
  }

  .list-search {
    margin-bottom: 1rem;
  }

  .selected-count {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .ui.table.databases-table {
    margin-top: 1rem;
  }

  .databases-table {
    .alert-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
      font-weight: 700;
      color: var(--color-white);
      border-radius: 50%;
      background-color: var(--color-severity-critical);

      &.success {
        background-color: var(--color-success);
      }
    }

    .permit-list {
      width: 15rem;
    }

    .status-container {
      width: 6rem;

      .progress-bar {
        height: 6px;
        background-color: var(--color-structured-data-bg);
        border-radius: 0px;

        .progress-completed {
          height: 100%;
          background: #ef9e54;
          border-radius: 0px;
          &.green {
            background: #3bb354;
          }
        }
      }

      .text {
        color: #6a737d;
        font-size: 0.75rem;
        font-weight: 500;
      }
    }

    .label {
      border-radius: 2px;
      color: var(--color-structured-databases-label);
      font-size: 0.92rem;
      font-weight: 600;
      padding: 0.3rem 0.8rem;
      margin-left: 0.3rem;

      &.progress {
        background-color: var(--color-structured-databases-bg-label-completed);
      }
    }
  }
}

.databases-scan {
  display: flex;
  flex-flow: column nowrap;
  position: relative;

  .db-scan-settings {
    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #dfe3e8;

    .title {
      margin: 0;
      font-size: 0.86rem;
      font-weight: 400;
      color: var(--color-text-primary);
    }

    .db-tabs {
      margin: 1rem 0;

      .radio-custom {
        margin-top: 0.5rem;
        .description {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }

        .popup-info-trigger {
          margin-left: 0.5rem;
        }
      }
    }

    .add-to-scan-list {
      margin-top: 1rem;
      padding: 0.3rem 1.5rem;
      border: 1px solid var(--color-primary-light);
      border-radius: 4px;
      font-size: 0.86rem;
      font-weight: 600;
      color: var(--color-primary-light);
    }
  }

  &.edit-page {
    .db-scan-settings {
      margin-top: 0;
    }
  }

  .ui.dropdown.dropdown-add-item,
  .add-new-db {
    height: 2.7rem;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
  }

  .db-dropdown-trigger,
  .add-new-db {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 20rem;
    font-size: 0.86rem;
    font-weight: 600;
    color: var(--color-primary-light);
    background: none;

    .icon-box {
      margin-right: 0.5rem;
    }
  }

  .add-new-db {
    margin-top: 1rem;
    padding: 0 1rem;
    padding-top: 0.2rem;

    .text {
      margin-top: -0.1rem;
    }
  }

  .db-input {
    margin-top: 1rem;
    width: 20rem;
    height: 2.7rem;
  }

  .list-search {
    width: 20rem;
    height: 2.7rem;
    margin-top: 2rem;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    outline: none;

    input {
      border: none;
    }
  }

  .db-list-control {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .selected-count {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 1rem;
    }

    .btn.remove {
      color: var(--color-white);
      background-color: var(--color-primary-light);
      padding: 0 1.5rem;
      height: 2rem;
      border-radius: 2px;
      font-size: 0.86rem;
      font-weight: 600;
    }
  }

  .db-scan-table {
    margin-top: 0rem !important;

    .field-selectable {
      .ui.checkbox {
        margin-right: 2.2rem;
      }
    }

    .db-name svg {
      height: 1.5rem;
      flex-shrink: 0;
    }
  }
  .workgroup-input {
    input {
      width: 20rem;
      height: 2.7rem;
      margin-top: 2rem;
      border: 1px solid #dfe3e8;
      border-radius: 4px;
      outline: none;
    }
  }
}

.header-wrapper {
  display: flex;
  color: var(--color-secondary-nav-text);
  .justify-right {
    margin-left: auto;
    align-self: center;
  }
}

.widget-databases-by-pii-tables {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-width: 13rem;
  justify-content: center;

  .empty-state-text {
    margin: 2rem;
  }

  .chart-text {
    text-align: center;
    margin-top: -1rem;
    font-size: 0.86rem;
  }

  .widget-database-container {
    margin-top: 2rem;
    display: flex;
    min-width: 13rem;
    position: relative;
    width: 100%;
    height: 100%;

    &.vertical {
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      .legend {
        display: flex;
        flex-flow: row wrap;
        align-items: baseline;
      }
    }
    &.horizontal {
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;

      .legend {
        display: flex;
        flex-flow: column nowrap;
        margin-left: 1rem;

        .item {
          line-height: 1.2em;
        }

        .key {
          width: 7rem;
          margin-right: 1rem;
        }
      }
    }
  }

  .recharts-wrapper svg {
    overflow: visible;
  }

  .icon-box {
    display: flex;
  }

  .legend {
    .legend-horizontal-title {
      font-weight: 600;
      font-size: 0.72rem;
    }
    .item {
      font-size: 0.86rem;
      display: flex;
      align-items: center;
      color: var(--color-river-bed);
      margin-top: 0.5rem;

      .circle {
        flex-shrink: 0;
        flex-grow: 0;
        width: 0.61rem;
        height: 0.61rem;
        margin-right: 0.3rem;
        border-radius: 50%;
      }
    }
  }
}
