/***************--------Font Mixins----------**************/

.text-regular {
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}

.text-semiBold {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.text-thin {
  .text-regular;
  line-height: 22px !important;
}

.font-basic {
  font-family: montserrat;
  color: var(--color-grey-1);
}

.font-normal(@fontSize, @lineHeight) {
  font-size: @fontSize;
  line-height: @lineHeight;
  font-weight: normal;
}

.font-bold(@fontSize, @lineHeight) {
  font-size: @fontSize;
  line-height: @lineHeight;
  font-weight: 700;
}

.font-semiBold(@fontSize, @lineHeight) {
  font-size: @fontSize;
  line-height: @lineHeight;
  font-weight: 600;
}

.font-medium(@fontSize, @lineHeight) {
  font-size: @fontSize;
  line-height: @lineHeight;
  font-weight: 500;
}
.font-light(@fontSize, @lineHeight) {
  font-size: @fontSize;
  line-height: @lineHeight;
  font-weight: 400;
}
.button-basic {
  border-radius: 4px;
  position: relative;
  padding: 0.5em 1.43em;
  font-size: 1rem;
  border: 1px solid;
}

.button-primary {
  .button-basic();
  background-color: var(--color-bg-btn-primary);
  color: var(--color-text-btn-primary);
  box-shadow: none;
}

.button-primary-inverted {
  .button-basic();
  background-color: var(--color-text-btn-primary);
  color: var(--color-bg-btn-primary);
  box-shadow: none !important;
}

.button-secondary {
  .button-basic();
  background-color: var(--color-bg-btn-secondary);
  border-color: var(--color-btn-secondary-border);
  color: var(--color-text-btn-secondary);
  box-shadow: none;
}

.text-primary {
  color: var(--color-primary);
}
.text-secondary {
  color: var(--color-secondary);
}
.text-tertiary {
  color: var(--color-tertiary);
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.box-info-bordered(@borderColor, @borderRadius) {
  border: 1px solid @borderColor;
  border-radius: @borderRadius;
}

.button-disabled {
  background-color: var(--color-mystic);
  color: var(--color-alerts-action-text);
  cursor: not-allowed;
  pointer-events: all !important;
}
