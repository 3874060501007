.modal-container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-white);
  border: 1px solid var(--color-dusty-gray);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 1.3rem;
  color: initial;

  .ui.header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 0.6rem;
    border-bottom: 1px solid var(--color-grey-9);
    margin: 0;

    span {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      color: var(--color-oxford-blue);
    }

    .btn-close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 50%;
      color: var(--color-white);
      background-color: var(--color-grey-11);
      padding: 0;
      margin-top: -0.7rem;
    }
  }

  .body {
    width: 100%;
  }

  .footer {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    width: 100%;

    .ui.button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
      align-self: center;
      margin-right: 2rem;
      background: none;

      &,
      &:hover,
      &.primary,
      &.primary:hover {
        border: 1px solid var(--color-primary);
        border-radius: 4px;
        padding: 0.8rem;
      }

      &.primary {
        background-color: var(--color-primary);
      }

      &:hover {
        opacity: 0.85;
      }
    }
  }
}
