.main-container {
  width: 100vw;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  .title {
    font-family: Inter !important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #3f4db0;
    margin: 2rem 0 1rem 0;
  }
  .sub-title {
    font-family: Inter !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }

  .button.ui.primary {
    padding: 0.8rem 1rem;
    border-radius: 6px;
    font-family: Inter !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}
