@import '../../../../assets/mixins.less';

.header-with-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-white);
  box-shadow: 0px 2px 4px #d7dde2;
  position: relative;
  z-index: 1;

  flex-direction: column-reverse;
  .scr-title {
    .font-semiBold(1rem, 1.72em);
    color: var(--color-filters-screen-text);
  }

  .header-actions {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .header-with-actions {
    flex-direction: row;
  }
}
