@import '../../../dashboards/dashboard.less';
.status-widget {
  display: flex;
  position: relative;
  background-color: var(--color-white);
  flex-grow: 1;
}

.status-widget {
  flex-direction: column;
  .status-info {
    display: flex;
    min-height: 12rem;
    flex-direction: column;
    flex-grow: 1;
    header {
      .font-medium(1rem, 1.2em);
      text-transform: uppercase;
      color: #24292e;
      display: inline-flex;
      align-items: center;

      & + div {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        span {
          .font-medium(0.86rem, 1.25em);
          display: flex;
          color: #444d56;
          &.status {
            color: #586069;
          }

          strong {
            font-weight: 900;
          }
        }
        .icon-info {
          align-items: center;
        }
      }
    }
  }
}

.ds-status-label {
  &.doconnected-or-deleting {
    display: flex;
    align-items: center;
    flex-direction: column;
    .objects-info {
      font-weight: normal;
      font-size: 0.86rem;
      color: #6a737d;
      line-height: 16px;
    }
    .status {
      .font-semiBold(1rem, 1.7em);
    }
  }
  &.ui.label {
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    color: var(--color-white);
    padding-top: 3px;
    padding-bottom: 3px;
    background: #fc7d7d;

    span {
      font-weight: 600;
      font-size: 0.72rem;
      line-height: 12px;
    }

    svg {
      margin-right: 3px;
    }

    &.scanning,
    &.registering,
    &.paused,
    &.pausing,
    &.resuming {
      border-radius: var(--size-6px);
      padding: 0 var(--size-8px);
      background: #e8f4d8;
      color: #386d07;

      svg {
        stroke: #386d07;
      }
    }
  }
  &.structured {
    display: flex;
    align-items: center;
    padding: 0.1rem 0.3rem;
    border-radius: 2px;
    background-color: var(--color-structured-data-bg-sample-active);
    font-size: var(--size-12px);
    font-weight: 400;
    text-transform: capitalize;

    .icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-right: 0.5rem;
    }

    &.scanned {
      border-radius: var(--size-6px);
      padding: 0 var(--size-8px);
      background: #e8f4d8;
      color: #386d07;
    }
  }
}
