@import '../../features/common.less';

.ui.basic.table,
.ui.basic.table.sortable {
  color: var(--color-grey-1);
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0;

  &.scrollable {
    @media (max-width: 1200px) {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      thead {
        display: block;
        min-width: 100%;
        width: fit-content;
      }

      tbody td {
        max-width: 20rem;
      }
    }

    @media (max-width: 768px) {
      tbody td {
        max-width: none;
      }
    }
  }

  thead {
    background: var(--color-table-header-bg);
    border-bottom: 1px solid var(--color-table-row-separator);

    th,
    th:hover,
    th.sorted,
    th.sorted:hover {
      .font-medium(1rem, 1.43);
      color: var(--color-table-text);
      border-left: 0;
      background: inherit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 1rem 1.75rem;
      cursor: default; // remove default cursor when Integrating sorting from Backend
      border-bottom: none;

      &:after {
        color: var(--color-table-sort-indicator);
        opacity: 1;
      }
    }

    th .field-tooltip {
      display: flex;
    }
    th .field-selectable .ui.checkbox {
      vertical-align: top;
    }

    th.dropdown-actions,
    th.table-actions {
      width: 4rem;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--color-table-row-separator);
      &.clickable {
        cursor: pointer;
      }

      td {
        .font-normal(1rem, 1.43);
        border-top: 0;
        color: var(--color-table-text);
        position: relative;
        padding: 0;

        .title-column,
        .regular-column,
        .checkbox-column,
        .with-subtext-column {
          padding: 1rem 1.75rem;
          &:not(.mobile.only) {
            .is-external-link {
              cursor: pointer;
              display: flex;
              width: 100%;
              align-items: center;
              min-width: 0;
              color: var(--color-table-text);

              .icon-external {
                display: none;
                width: unset;
              }

              &:hover {
                color: var(--color-primary-light);
                .icon-external {
                  position: absolute;
                  right: 0;
                  display: flex;
                }
              }

              .icon-external {
                width: 1.43rem;
              }
            }
          }
          .content-subtext-wrapper {
            .content {
              .font-medium(1rem, 1.43rem);
            }
            .subtext {
              .font-medium(0.75rem, 1rem);
              color: --color-text-secondary;
            }
          }
        }

        .title-column:not(.mobile.only) {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;

          a {
            display: block;
            width: 100%;
            color: var(--color-table-text);

            &:hover {
              color: var(--color-primary-light);
            }
          }

          &.number {
            text-align: center;
            & > a > span {
              display: flex;
              width: 100%;
              justify-content: center;
            }
          }
        }

        .regular-column.with-url a {
          display: block;
          color: var(--color-table-text);

          &:hover {
            color: var(--color-primary-light);
          }
        }

        .regular-column:not(.mobile.only) {
          &.number.ui.grid {
            align-items: stretch;
            justify-content: center;
          }
        }

        .actions {
          text-align: center;

          .ellipsis.icon {
            margin: 0;
            color: var(--color-grey-6);
          }
        }

        &.dropdown-actions {
          div.hidden {
            justify-content: flex-end;
          }

          .m-content {
            padding-top: 1rem;
          }
        }

        .mobile.only.m-content {
          .li.first {
            padding-bottom: 0.25em;
          }

          .li.last {
            padding-top: 0.25em;
          }

          .li {
            display: flex;
            align-items: center;
            width: 100%;

            .title-mobile {
              flex-basis: 0;
              margin: 0;
              flex-grow: 1;
              margin-right: 1rem;
              font-weight: 600;
              white-space: nowrap;
            }

            .content-mobile {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              min-height: 1.5rem;
            }
          }

          .button {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .checkbox-column {
    a {
      color: inherit;
      &:hover {
        color: var(--color-primary-light);
      }
    }

    .checkbox-custom {
      padding-left: 0;
      margin-top: 0;
      width: 100%;

      &,
      .checkbox-custom-label,
      .content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
      }

      .checkbox-custom-label {
        margin-left: -2px;
      }
    }

    & > div {
      flex-wrap: nowrap;
    }
  }
}

.column-tooltip {
  max-width: 25rem !important;
}

@media screen and (min-width: 768px) {
  .ui.basic.table,
  .ui.basic.table.sortable {
    tbody {
      tr {
        .actions-column {
          display: flex;
          justify-content: flex-end;

          .actions {
            padding: 0;
          }
        }
      }
    }
    thead {
      &.header-hidden {
        visibility: hidden;
      }

      th,
      th:hover,
      th.sorted,
      th.sorted:hover {
        &.number {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .ui.basic.table,
  .ui.basic.table.sortable {
    thead {
      &.header-hidden {
        display: none;
      }
    }
  }
}
