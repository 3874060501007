@import '../../../../assets/mixins.less';

.select-filter-option {
  min-width: 100%;
  background: var(--color-page-bg);
  header {
    margin-bottom: 5.75rem;
  }

  .select-filter-content {
    text-align: center;
    .primary-text {
      .font-normal(1.72rem, 1.33em);
    }

    .secondary-text {
      .font-normal(1rem, 1.43em);
      color: var(--color-filters-screen-text);
    }

    .filter-options {
      display: flex;
      justify-content: center;
      margin-top: 4rem;
      .box-import-file {
        background-color: var(--color-white);
        padding: 2.86rem 4.3rem;
        box-shadow: 0px 2px 2px var(--color-filters-box-shadow);
        border-radius: 3px;
        cursor: pointer;

        &.active {
          background: var(--color-filters-box-active-bg);
          border: 1px solid var(--color-filters-box-active-border);
        }
      }
    }
  }
}

.import-file-entity-filter {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .font-normal(1.14rem, 1.51em);
    color: var(--color-filters-screen-text);

    svg {
      cursor: pointer;
    }
  }

  .import-control {
    display: flex;
    border-radius: 3px;
    border: 1px solid var(--color-filters-popup-import-file-border);
    .file-path {
      flex-grow: 1;
      align-items: center;
      display: flex;

      .ui.input {
        width: 100%;
        input {
          border: 0;
          padding: 0;
        }
      }
    }

    .select-option,
    button {
      width: 17%;
      cursor: pointer;
    }

    .select-option {
      .ui.selection.dropdown {
        background: var(--color-filters-popup-import-dropdown-options);
        border: 0;
        border-radius: 0;
        .dropdown.icon {
          height: 100%;
          top: 0;
          margin-top: 0;
          display: flex;
          align-items: center;
        }
      }
    }

    button {
      background-color: transparent;
      border: 0;
      color: var(--color-white);
      background: var(--color-filters-popup-import-select-file-btn);
    }
  }

  .help-text {
    .font-normal(0.86rem, 2em);
    color: var(--color-filters-popup-import-help-text);
    margin-left: calc(17% + 0.86rem);
  }

  .file-import {
    display: flex;
    justify-content: center;
  }
}
