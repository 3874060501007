.page-global-search {
  flex-grow: 1;
  overflow-y: auto;
}

.page-global-search-results {
  margin-top: 2rem;
  padding: 2rem;
  border: 1px solid var(--color-geyser);
  border-radius: 4px;

  .ui.list.records-container {
    margin-top: 2.2rem;
    max-height: none;
    overflow-y: auto;
    flex-grow: 1;
  }
}

.global-search {
  .ui.input input {
    border: none;
    color: var(--color-white);
  }

  .ui.input input,
  .ui.input input:active,
  .ui.input input:focus {
    background-color: inherit;
    color: var(--color-white);
  }

  border-left: 1px solid rgba(255, 255, 255, 0.15);
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  // padding: 0 1.5rem;
}
