//=========================================
//              FOR EACH LOOP             =
//=========================================
.for(@array) when (default()) {
  .for-impl_(length(@array));
}

.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}

.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}
//--------  End of for each loop  ---------//

///////////////
// variables //
///////////////
@screen-xs: 0;
@screen-md: 768px;
@screen-lg: 992px;
@screen-xl: 1200px;

@values-array: 0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84,
  88, 92; // 24 values in total

// global
@media (min-width: @screen-xs) {
  .make-space(@prefix: xs);
}
// tablet
@media (min-width: @screen-md) {
  .make-space(@prefix: md);
}
// desktop
@media (min-width: @screen-lg) {
  .make-space(@prefix: lg);
}
// large screens
@media (min-width: @screen-xl) {
  .make-space(@prefix: xl);
}

// init functions
.make-space(@prefix) {
  .for(@values-array);
  .-each(@value) {
    .@{prefix}-p-@{value} {
      padding: ~'@{value}px !important';
    }
    .@{prefix}-pt-@{value} {
      padding-top: ~'@{value}px !important';
    }
    .@{prefix}-pl-@{value} {
      padding-left: ~'@{value}px !important';
    }
    .@{prefix}-pr-@{value} {
      padding-right: ~'@{value}px !important';
    }
    .@{prefix}-pb-@{value} {
      padding-bottom: ~'@{value}px !important';
    }
    .@{prefix}-px-@{value} {
      padding-left: ~'@{value}px !important';
      padding-right: ~'@{value}px !important';
    }
    .@{prefix}-py-@{value} {
      padding-top: ~'@{value}px !important';
      padding-bottom: ~'@{value}px !important';
    }

    .@{prefix}-m-@{value} {
      margin: ~'@{value}px !important';
    }
    .@{prefix}-mt-@{value} {
      margin-top: ~'@{value}px !important';
    }
    .@{prefix}-ml-@{value} {
      margin-left: ~'@{value}px !important';
    }
    .@{prefix}-mr-@{value} {
      margin-right: ~'@{value}px !important';
    }
    .@{prefix}-mb-@{value} {
      margin-bottom: ~'@{value}px !important';
    }
    .@{prefix}-my-@{value} {
      margin-top: ~'@{value}px !important';
      margin-bottom: ~'@{value}px !important';
    }
    .@{prefix}-mx-@{value} {
      margin-left: ~'@{value}px !important';
      margin-right: ~'@{value}px !important';
    }
  }
}
