@import '../../../../assets/mixins';

.test-connection-status {
  .test-connection {
    display: flex;
    align-items: center;
  }

  .ui.basic.button.btn-test-connection {
    .font-normal(1rem, 1.22);
    padding: 0.57em 1.43em !important;
    color: var(--color-non-click-text) !important;
    border-radius: 24px !important;
    border: 1px solid var(--color-inputs-border-default);
    box-shadow: none;
    white-space: nowrap;

    &.success {
      background-color: var(--color-success) !important;
      color: var(--color-white) !important;
      border: 1px solid var(--color-success);
    }

    &.error {
      background-color: var(--color-error) !important;
      color: var(--color-white) !important;
      border: 1px solid var(--color-error);
    }
  }

  .status-message {
    .font-normal(1rem, 20px);
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: var(--color-non-click-text) !important;
  }
}
