@import '../../assets/mixins.less';

.ui.message.notification-container {
  position: fixed;
  margin-top: 0;
  top: calc(var(--navbar-height) + 0.72rem);
  right: 4px;
  z-index: 9999;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;

  .ui.message {
    padding-right: 2.5rem;
    min-width: 29rem;
    max-width: 50vw;
    border-radius: 4px;
    display: flex;
    .content {
      flex-basis: 0;
      flex-grow: 1;
    }
    .msg-content {
      .font-normal(0.86rem, 1.5em);
    }
    .msg-title {
      .font-semiBold(1rem, 1.43em);
    }
    p {
      opacity: 1;
    }
    &.positive {
      background-color: var(--color-notification-success-bg) !important;
      border: 1px solid var(--color-notification-success-border-color);
      box-shadow: 0px 1.2px 3.6px rgba(36, 41, 46, 0.1), 0px 6.4px 14.4px rgba(36, 41, 46, 0.13) !important;
      p {
        color: var(--color-notification-success-text-color);
      }
      .icon.circle {
        color: var(--color-notification-success-icon-color);
      }
    }

    &.negative {
      background-color: var(--color-notification-error-bg) !important;
      border: 1px solid var(--color-notification-error-border-color);
      box-shadow: 0px 1.2px 3.6px rgba(36, 41, 46, 0.1), 0px 6.4px 14.4px rgba(36, 41, 46, 0.13) !important;
      p,
      .icon.circle {
        color: var(--color-notification-error-text-color);
      }
    }

    .icon.circle {
      font-size: 1.43rem;
      margin-right: 1.29rem;
    }

    .close.icon {
      font-size: 0.72rem;
      color: var(--color-icon-dismiss);
      margin-top: 0.64rem;
      font-size: 1rem;
    }

    .icon {
      display: flex;
      align-items: center;
      flex-basis: 0;
      flex-grow: 0;
    }
  }
}
