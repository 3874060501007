@import '../../assets/mixins';
// @detailSectionHeight: 63rem;

@media screen and (max-width: 800px) {
  .dashboard .alerts-widget,
  .dashboard .anomalies-widget {
    .chart {
      margin-right: 1rem;
    }
  }
}

.dashboard {
  padding: 0 !important;

  .ui.header {
    width: 100%;
    .datasource-header-container {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      background-color: var(--color-white);
      padding: 0 1.5rem 1.5rem;
    }
  }

  .dashboard-container {
    border-radius: 6px;
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.16), 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
  }

  .section-above-header {
    padding: 0 !important;
    .ant-alert {
      width: 100%;
      border-radius: 0;
      border: 0;
    }
  }
}

.dashboard .drive-details {
  .entities-list-compact {
    width: 100%;
    .search-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row nowrap;
      white-space: nowrap;
      border: 1px solid var(--color-page-content-border);
      padding: 0.3rem 0.8rem;
      border-radius: 4px;
      font-size: 0.92rem;
      margin: 1rem;
      .ui.search {
        flex-grow: 1;
        height: 100%;
        .ui.input {
          margin-left: 0;
          width: 100%;
          height: 100%;
          input {
            border: none;
            padding: 0;
            margin: 0;
            margin-left: 0.5rem;
          }
        }
      }
    }
    .no-results {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .right-side {
    .overview {
      display: grid;
      gap: var(--size-16px);
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'widget-1 widget-3' 'widget-2 widget-3';

      .widget-1 {
        grid-area: widget-1;
        width: 100%;
        padding: 1rem;
      }

      .widget-2 {
        grid-area: widget-2;
        width: 100%;
        padding: 1rem;
      }

      .widget-3 {
        grid-area: widget-3;
        width: 100%;
        padding: 0;
      }
    }

    .section-detail .drive-detail-tabs {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

@media screen and (max-width: 1380px) {
  .dashboard .drive-details {
    .right-side {
      .overview {
        grid-template-columns: 1fr;
        grid-template-areas: 'widget-1' 'widget-2' 'widget-3';
      }
    }
  }
}

.dashboard .drive-details,
.dashboard .bucket-details {
  .left-side {
    height: auto;

    .drives-compact-listing {
      position: relative;
      width: 100%;
    }
  }
  .right-side {
    display: flex;
    flex-direction: column;

    .attributes {
      .filters-wrapper {
        width: unset;
      }
    }

    .section-detail {
      display: flex;
      flex-direction: column;
      min-height: 0;

      .drive-subviews {
        .list-files {
          .controls {
            justify-content: end;
            .filters-wrapper {
              width: unset;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .right-side {
      height: auto;
    }
  }
  .count-risky {
    color: var(--color-risk-text);
    white-space: pre-wrap;
    word-wrap: break-word;
    &:after {
      content: '/';
      display: inline;
      color: var(--color-text-secondary);
      margin: 0 4px;
      font-weight: 400;
    }
  }

  .widget-summary.entities .icon-container {
    background-color: var(--color-entities-summary-icon-bg);
  }

  .widget-summary.files .icon-container {
    background-color: var(--color-files-summary-icon-bg);
  }

  .overview {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1200px) {
      flex-direction: row;
    }
    .pie-widgets {
      display: flex;
      flex-direction: column;
    }

    @media screen and (min-width: 1200px) {
      .alerts-info {
        width: 33%;
        height: auto;
      }

      .pie-widgets {
        width: 67%;
      }
    }
  }

  .summary-widgets {
    max-height: unset;
    flex-direction: column;
    gap: 1rem;

    & > .dashboard-container {
      width: unset;
      @media screen and (min-width: 992px) {
        width: 33%;
      }
    }

    @media screen and (min-width: 992px) {
      flex-direction: row;
      display: flex;
      max-height: 14.5rem;
    }
  }

  .filters-wrapper {
    justify-content: flex-end;
  }
}

.associated-labels {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin: 12px 0;
  .selected-labels {
    margin-top: 0;
  }
}

.dashboard.gdrive {
  padding: 0;
  margin: 0;

  .section-above-header {
    background-color: var(--color-white);
  }
  .section-header {
    margin-bottom: 0rem;
  }
  .dashboard-content {
    margin-top: 0rem;
    // padding: 0 1.5rem 1.5rem;
  }
  .summary-widget-container .summary-container {
    margin: 0;
  }
}

.lb-page-header {
  .summary-container {
    margin-left: 0;
    justify-content: flex-start;
  }
}

.section-content {
  .dashboard-children {
    padding: var(--size-24px);
  }
}
