.search-container {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .search-box {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    width: 320px;

    .svg-icon {
      scale: 0.75;
      path {
        stroke: #454545 !important;
      }
    }

    .ui.input {
      width: 100%;
      max-width: 280px;
      height: 24px;
      input {
        width: 100%;
        border-radius: 2px;
        color: #454545 !important;
        padding: 0.4285714286rem;
      }
    }
  }
}

.results-container {
  margin-top: 0.2rem;
  position: absolute;
  background-color: var(--color-white);
  z-index: 2;
  width: 100%;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.45);
  border-radius: 4px;
  padding: 2rem 1.7rem 1.5rem 1.7rem;
  top: 3rem;
  left: 0;
  .ui.basic.button.button-view-all {
    box-shadow: none;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 2rem;
    color: var(--color-primary) !important;
    background: inherit;
    cursor: pointer;
  }
}

@media screen and (max-width: 1280px) {
  top: 6rem;
}

.labels-container {
  .ui.header.labels-container-header {
    font-weight: 600;
    font-size: 1rem;
    line-height: 2rem;
    color: var(--color-ebony-clay);
    margin: 0;
  }

  .label.label-item {
    border: 1px solid var(--color-geyser);
    border-radius: 4px;
    background-color: var(--color-athens-grey);
    margin-right: 0.5rem;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 2rem;
    color: var(--color-ebony-clay);
    padding: 0 0.7rem;
  }
}

.records-container {
  max-height: 50vh;
  overflow-y: auto;

  .ui.header.records-container-header {
    font-weight: 600;
    font-size: 1rem;
    line-height: 2rem;
    color: var(--color-ebony-clay);
    margin: 0;
  }

  .result-item {
    display: flex;
    flex-direction: column;
    padding-top: 0.4rem;
    padding-bottom: 0.6rem;
    border-bottom: 1px solid var(--color-geyser);

    .header-like {
      font-size: 1rem;
      line-height: 2rem;
      color: var(--color-ebony-clay);
    }

    &:hover .header-like {
      color: var(--color-science-blue);
    }

    .content {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;

      div {
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 1.2rem;
        display: flex;
        align-items: center;
        color: var(--color-lynch);
        width: 80px;
      }
    }
  }
}
