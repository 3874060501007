@import '../../assets/mixins.less';

.table-trend-widget {
  .ui.table {
    th {
      background-color: transparent;
      .text-count {
        .font-semiBold(1.35rem, 1.47em);
        color: var(--color-emoby-clay);
      }
      .text-title {
        .font-normal(0.92rem, 1.15em);
        color: #4f4f4f;
      }
    }
    td {
      .font-normal(1rem, 1.14em);
    }
  }
}
