@import '../../../../assets/mixins.less';

.ui.buttons.condition-combinators {
  border: 1px solid var(--color-filter-combinator-border);
  border-radius: 4px;

  .ui.button {
    background: var(--color-white);
    width: 3rem;
    color: var(--color-filter-combinator-text);
    .font-normal(0.86rem, 1.25em);

    &:last-child {
      border-left: 1px solid var(--color-filter-combinator-border);
    }

    &.on {
      background-color: var(--color-filter-combinator-active-bg);
    }
  }

  & + .fields-group {
    margin-top: 1.72rem;
  }
}
