@import '../assets/mixins';
@import '../assets/spacing';

:root {
  /** New schema colors **/
  --color-pale-sky: #6e7481;
  --color-mystic: #e9edf2;
  --color-mystic-2: #e2e7ee;
  --color-mystic-3: #d7dfe9;
  --color-dodger-blue: #2771ff;
  --color-shark: #1b1d21;
  --color-midnight-blue: #00396b;
  --color-science-blue: #0072d4;
  --color-manatee: #9097a0;
  --color-emerald: #4fbf67;
  --color-shuttle-gray: #656b76;
  --color-thunderbird: #dd3716;
  --color-casper: #aab7cb;
  --color-lynch: #5f788c;
  --color-lynch-2: #61708f;
  --color-neon-carrot: #ff9930;
  --color-link-water: #f4f7fc;
  --color-jaffa: #f2a23d;
  --color-widget-risk-total: #959da5;
  --color-class-card-count: #000000;
  --color-class-card-description: #444d56;
  --color-filters-main: #444d56;
  --color-filters-bg: #e1e4e8;
  --color-azure-radiance: #0078e8;
  --color-scan-status: #2b399c;
  --color-text-primary: #24292e;
  --color-text-secondary: #6a737d;
  --color-text-third: #444d56;
  --color-text-forth: #001529;
  --color-table-inactive: #e1e4e8;
  --color-risk-ok: #c6f4d0;
  --color-risk-low: #f1a40f;
  --color-risk-medium: #ee803c;
  --color-risk-high: #f04647;
  --color-schema-main: #959da5;
  --color-table-gird-button: #444d56;
  --color-note-border: #b7b7b7;
  --color-note-background: #ffedbd;
  --color-text-area-border: #cbcbcb;
  /** Color namings: https: //chir.ag/projects/name-that-color **/
  --color-white: #ffffff;
  --color-black: #000000;
  --color-dusty-gray: #979797;
  --color-trout: #4e5662;
  --color-raven: #7d8089;
  --color-geyser: #dbe0e8;
  --color-ebony-clay: #242c39;
  --color-ebony-clay2: #2a3344;
  --color-ebony-clay3: #232b3a;
  --color-blush-pink: #ff86fb;
  --color-cadet-blue: #abb7ca;
  --color-bermuda-grey: #6f84a4;
  --color-athens-grey: #f3f5f7;
  --color-old-brick: #9b211c;
  --color-forest-green: #1fac24;
  --color-bali-hai: #8799b3;
  --color-heather: #c3ccd9;
  --color-heather2: #b6c0d2;
  --color-green-haze: #08b34c;
  --color-curious-blue: #2988bf;
  --color-hawkeys-blue: #ebf5fd;
  --color-shocking-pink: #ff1abf;
  --color-athens-gray: #f5f7f9;
  --color-green-vogue: #023058;
  --color-endeavour: #005299;
  --color-solitude: #eaf5ff;
  --color-slate-gray: #7c8696;
  --color-botticelli: #d5dee8;
  --color-gull-gray: #95a0b0;
  --color-purple-heart: #622cd6;
  --color-blue-chalk: #f3ebff;
  --color-yellow-sea: #ffaa0e;
  --color-oxford-blue: #374257;
  --color-bittersweet: #fd7461;
  --color-pasta: #ffb27a;
  --color-emoby-clay: #1b2229;

  // Primary btn bg
  --color-blue-1: #004785;
  --color-blue-2: #1898e2;
  --color-blue-3: #15bdff; // for calender
  --color-blue-4: #1e87c4; // charts
  --color-blue-5: #0078e8;

  // body base color
  --color-grey-1: #3d4060;

  --color-grey-2: #d8dbe9;
  --color-grey-3: #dadada;
  --color-grey-4: #626a93;
  --color-grey-5: #d2d5e3;
  --color-grey-6: #6c749d;
  --color-grey-7: #f4f5fa;
  --color-grey-8: #bcc2d9;
  --color-grey-9: #eaecf5;
  --color-grey-10: #f9faff;
  --color-grey-11: #9ba2bc;
  --color-grey-12: #f5f8fb;
  --color-grey-13: #f4f4f4;
  --color-grey-14: #dbe0e8;
  --color-grey-16: #45546c;

  --color-green-1: #2ac530;
  --color-green-2: #46beb2;
  --color-green-3: #e5f2e5;
  --color-orange-1: #feac15;
  --color-orange-2: #e48c2c;
  --color-orange-3: #fff6e5;

  --color-red-1: #f24b4b;
  --color-red-2: #faeae9;
  --color-red-3: #f7eaea;
  --color-red-4: #fda7a7;

  --color-maroon: #745989;
  --color-green: #46beb2;

  --navbar-height: 4.07142857143rem;
  --sidebar-width: 90px;

  // Colors for theming
  --color-primary: #d7dfe9;
  --color-secondary: #f2a23d;
  --color-tertiary: #dd3716;
  --color-small-background: #f6f8fa;
  --color-structured-data-bg: #f6f8fa;
  --color-structured-data-bg-secondary: #fef6f2;
  --color-structured-data-border: #e1e4e8;
  --color-ds-register-steps-inactive: #6a737d;
  --color-ds-register-steps-active-bg: #3e21ee;
  --color-ds-register-steps-active-text: #24292e;
  --color-ds-register-steps-connector: #b6bcc2;
  --color-structured-data-sensitive: #666fe8;
  --color-structured-data-bg-databases: #fff8ef;
  --color-structured-databases-icon: #ffa82c;
  --color-structured-databases-label: #444d56;
  --color-structured-databases-bg-label-completed: #def5de;
  --color-structured-data-bg-tables: #fff3ff;
  --color-structured-tables-icon: #e989e5;
  --color-structured-data-bg-columns: #f2f8ff;
  --color-structured-columns-icon: #449aff;
  --color-structured-data-bg-updated: #fff2f6;
  --color-structured-updated-icon: #ff5b8e;
  --color-structured-data-bg-confidence: #fbdecc;
  --color-structured-data-bg-columns-sensitive: rgba(102, 111, 232, 0.1);
  --color-structured-data-bg-columns-issues: rgba(238, 128, 60, 0.1);
  --color-structured-data-bg-table-meta-data: #fafbfc;
  --color-structured-data-checkbox-border: #b6bcc2;
  --color-structured-data-bg-schema-active: #eff1ff;
  --color-structured-data-profile-rs-main: #444d56;
  --color-structured-data-profile-rs-btn-border: #d1d5da;
  --color-structured-data-profile-rs-separator: #cccccc;
  --color-structured-data-profile-rs-shadow: #d7dde2;
  --color-structured-data-bg-sample-active: #f5f7ff;
  --color-structured-data-placeholder: #959da5;
  --color-structured-data-page-title: #959da5;
  --color-structured-data-text-default: #24292e;
  --color-structured-data-whats-new-bg: #fff4e3;
  --color-structured-data-whats-new-border: #ffc363;
  --color-structured-data-whats-new-text: #aa620b;
  --color-structured-data-tables-widget-border: #e6ebf1;
  --color-structured-data-tables-widget-new-added-bg: #fff4ed;
  --color-structured-data-tables-widget-new-added-border: #f6bb96;
  --color-structured-data-tables-widget-new-added-text: #eb6818;
  --color-structured-data-tables-widget-border-hover: #bac4ff;
  --color-structured-data-tables-widget-clusters-bg: #ffebdf;
  --color-structured-data-tables-widget-clusters-bg: #ffebdf;
  --color-vertical-menu-item-active-bg: #eef1ff;
  --color-default-bg: #edf3f6;
  --color-structured-data-confirm-notification-bg: #0b1823;

  --color-tables-widget-icon-bg: #ec9ae9;
  --color-columns-widget-icon-bg: #5c94cf;

  --color-alerts-chart-bg: #f6f8fa;
  --color-alerts-progress-bg: #e1e4e8;
  --color-alerts-progress-completed-bg: #2481e1;
  --color-alerts-summary-status-unresolved-bg: #fff4ed;
  --color-alerts-summary-status-unresolved: #eb6818;
  --color-alerts-summary-status-resolved-bg: #edf6ef;
  --color-alerts-summary-status-resolved: #31a040;
  --color-objects-impacted-public: #0f8aa5;
  --color-objects-impacted-public-bg: #e3f7fc;
  --color-objects-impacted-private: #de5fbb;
  --color-objects-impacted-private-bg: #fff2fb;
  --color-risk-text: #f19860;
  --color-files-summary-icon-color: #c684dd;
  --color-files-summary-icon-bg: #faf5ff;
  --color-entities-summary-icon-color: #e1618f;
  --color-entities-summary-icon-bg: #fff7fa;
  --color-attributes-summary-icon-color: #449aff;
  --color-attributes-summary-icon-bg: #fff7fa;
  --color-access-public: #ea9f0e;
  --color-access-private: #afb4b9;

  --color-btn-orange: #f04647;
  --color-btn-orange-hover: #ed3233;
}
[data-theme='theme1'] {
  --color-page-title: #24292e;
  --color-primary: #3f4db0;
  --color-primary-light: #3e21ee;
  --color-gradient-primary: #413ea7;
  --color-primary-background: #3f4db0;
  --color-primary-background-hover: #2b399c;
  --color-secondary-border-color: #444d56;
  --color-btn-secondary-border: #444d56;
  --color-text-btn-secondary: #24292e;
  --color-secondary: #f6bb96;
  --color-tertiary: #ee803c;
  --color-small-background: #f6f8fa;
  --color-warning-background: #eb6818;
  --color-warning-background-light: #fbece3;
  --color-mystic: #e1e4e8;
  --color-ebony-clay: #242c39;
  --color-non-click-text: #24292e;
  --color-class-card-count: #000000;
  --color-class-card-description: #444d56;
  --color-class-card-icon: #d1d5da;
  --color-filters-main: #444d56;
  --color-filters-bg: #e1e4e8;
  --color-table-inactive: #e1e4e8;
  --color-table-customer: #0f8aa5;
  --color-table-vendor: #f68319;
  --color-table-employee: #c346a8;
  --color-table-not-selected: #586069;
  --color-table-opened: #d7dde2;
  --color-risk-empty: #e1e4e8;
  --color-risk-ok: #c6f4d0;
  --color-risk-low: #f1a40f;
  --color-risk-medium: #ee803c;
  --color-risk-high: #f04647;
  --color-border-dark: #00152929;
  --color-border-separator: #dfe3e8;
  --color-schemas-search-placeholder: #959da5;
  --color-table-grid-search-placeholder: #959da5;
  --color-table-gird-button: #444d56;
  --color-ds-register-steps-inactive: #6a737d;
  --color-ds-register-steps-active-bg: #3e21ee;
  --color-ds-register-steps-active-text: #24292e;
  --color-ds-register-steps-connector: #b6bcc2;
  --color-structured-data-sensitive: #666fe8;
  --color-structured-data-bg-databases: #fff8ef;
  --color-structured-databases-icon: #ffa82c;
  --color-structured-databases-label: #444d56;
  --color-structured-databases-bg-label-completed: #def5de;
  --color-structured-data-bg-tables: #fff3ff;
  --color-structured-tables-icon: #e989e5;
  --color-structured-data-bg-columns: #f2f8ff;
  --color-structured-columns-icon: #449aff;
  --color-structured-data-bg-updated: #fff2f6;
  --color-structured-data-bg-updated: #fff2f6;
  --color-structured-updated-icon: #ff5b8e;
  --color-structured-data-bg-columns-sensitive: rgba(102, 111, 232, 0.1);
  --color-structured-data-bg-columns-issues: rgba(238, 128, 60, 0.1);
  --color-structured-data-bg-table-meta-data: #fafbfc;
  --color-structured-data-checkbox-border: #b6bcc2;
  --color-structured-data-bg-schema-active: #eff1ff;
  --color-structured-data-bg-sample-active: #f5f7ff;
  --color-structured-data-bg-edit: #586069;
  --color-structured-data-auto-discovered-text: #39abb8;
  --color-structured-data-page-header-border: #8ebfdd;
  --color-structured-data-page-header-item-bg: #f1faff;

  --color-entities-secondary: #959da5;
  --color-entities-bg-selected: #3e21ee0a;
  --color-entities-bg: #f6f8fa;
  --color-entities-border: #e1e4e8;
  --color-entities-warning: #eb6818;
  --color-entities-warning-background: #fbece3;
  --color-entities-tag-icon: #449aff;
  --color-entities-tag-bg: #f0f7ff;
  --color-entities-dataSource-icon: #c684dd;
  --color-entities-dataSource-bg: #faf5ff;
  --color-entities-alerts-icon: #8967e8;
  --color-entities-alerts-bg: #f4f3ff;
  --color-entities-title: #444d56;
  --color-entities-separator: #d1d5da;
  --color-entities-no-risk: #6a737d;
  --color-entities-no-risk-bg: #eff4fa;
  --color-entities-risk: #eb6818;
  --color-entities-risk-bg: #fff4ed;

  --color-carousel-arrow: #f6f8fa;

  --color-alerts-chart-bg: #f6f8fa;
  --color-alerts-progress-bg: #e1e4e8;
  --color-alerts-progress-completed-bg: #ff722b;
  --color-alerts-time: #959da5;
  --color-alerts-arrows: #444d56;
  --color-alerts-bar: #ff722b;
  --color-alerts-overview-bg: #fafbfc;
  --color-alerts-overview-icon: #b6bcc2;
  --color-alerts-overview-icon2: #d1d5da;
  --color-alerts-overview-description: #959da5;
  --color-popup-bg: #2f363d;
  --color-popup-close-btn: #2f363d;
  --color-alerts-action-text: #959da5;
  --color-alerts-action-taken-label: #586069;
  --color-alerts-action-taken-label-bg: #eff4fa;
  --color-alerts-action-taken-input-like-border: #d1d5da;

  --color-data-sources-widget-risk-total: #2481e1;
  --color-data-sources-widget-risk-risky: #f19860;
  --color-data-sources-widget-icon: #444d56;
  --color-data-sources-widget-bg: #f6f8fa;

  --color-severity-info: #3e21ee;
  --color-severity-warning: #e67a27;
  --color-severity-critical: #fc7d7d;

  --color-attributes-card-icon-bg: #f1f3ff;
  --color-attributes-card-bg: #fafbfc;
  --color-attributes-grey: #6a737d;
  --color-attributes-card-bg: #fafbfc;

  --color-scanned-data-button-border: #bac4ff;
  --color-scanned-data-button-active-bg: #eff1ff;
  --color-scanned-data-documents-bar: #009dff;
  --color-scanned-data-attributes-bar: #5f23fd;

  --color-dropdown-placeholder: #959da5;
  --color-inputs-border-default: #d1d5da;
  --color-inputs-focussed-border: #3f4db0;
  --color-input-icon: #3f4db0;
  --color-form-field-label: #4e5662;
  --color-form-field-error: #f04647;
  --color-bg-btn-primary: #3e21ee;
  --color-text-btn-primary: #fff;
  --color-bg-btn-secondary: #fff;

  --color-bg-btn-disabled: #fff;
  --color-text-btn-disabled: #d1d5da;
  --color-border-btn-disabled: #d1d5da;

  --color-navbar-secondary-border-bottom: #d1d5da;
  --color-secondary-nav-text: #3f4db0;
  --color-secondary-nav-selected-indicator: #3f4db0;

  --color-primary-nav-text: #3e21ee;
  --color-primary-nav-border: #3e21ee;
  --color-primary-nav-active-text: #fff;
  --color-primary-nav-active-bg: #3e21ee;

  --color-checkbox-border: #6a737d;
  --color-checkbox-default-bg: #959da5;
  --color-checkbox-bg: #3e21ee;
  --color-success: #4fbf67;
  --color-error: #f04647;

  --color-table-legend: #959da5;
  --color-page-bg: #f3f3f5;
  --color-page-content-border: #d1d5da;

  --color-widget-risk-total: #959da5;

  --color-table-header-bg: #f6f8fa;
  --color-table-row-separator: #e8eaec;

  --color-table-text: #24292e;
  --color-table-sort-indicator: #3f4db0;

  --color-panigation-text: #3f4db0;
  --color-panigation-text-active: #6a737d;
  --color-panigation-text-active-bg: rgba(225, 228, 232, 0.5);

  --color-link: #3e21ee;
  --color-link-bg: #f5f9ff;

  --color-river-bed: #444d56;
  --color-icon-left-nav: #6e7481;
  --color-dashboard-widget-summary-title: #6a737d;
  --color-round-trail-empty: #e1e4e8;
  --color-classified-item: #225ea8;
  --color-unclassified-item: #41b6c4;

  --color-status-warning: #f1a40f;
  --color-status-ok: #3bb254;
  --color-status-danger: #f04647;

  --color-scan-status: #2b399c;
  --color-policy-dashboard-separator: #e1e4e8;
  --color-legend-policy-types: #444d56;
  --color-policy-dashboard-title: #444d56;
  --color-policy-text-grey: #556674;
  --color-policy-dashboard-description: #6a737d;

  --color-policyType-title: #24292e;

  --color-vertical-menu-item-active-bg: #eef1ff;
  --color-policyType-active-border: #3f4db0;

  --color-policyType-activeCount-text: #127227;
  --color-policyType-activeCount-bg: #f4faf6;

  --color-policyType-disabledCount-text: #444d56;
  --color-policyType-disabledCount-bg: #f6f8fa;

  --color-policyType-summary-card-title: #666666;
  --color-policyType-summary-card-count: #24292e;

  --color-dropdown-icon: #959da5;
  --color-dropdown-tag-bg: #f6f8fa;
  // --color-dropdown-tag-border: #eb6818;
  --color-dropdown-tag-text: #24292e;
  --color-dropdown-tag-remove: #444d56;
  --color-policy-basic-info-title: #444d56;
  --color-policy-basic-info-description: #959da5;
  --color-policy-detail-label: #444d56;

  --color-severity-critical: #fc7d7d;
  --color-severity-info: #3e21ee;
  --color-severity-warning: #f1b339;

  --color-alert-detail-fields-title: #6a737d;
  --color-alert-detail-fields-value: #24292e;
  --color-alert-detail-popup-border: #bac4ff;
  --color-alert-detail-popup-hover: #f5f7ff;
  --color-policy-icon: #b6bcc2;
  --color-bulleted-text: #959da5;
  --color-alert-list-item-title: #24292e;
  --color-alert-list-title: #444d56;
  --color-filters-menu-item: #444d56;
  --color-filters-menu-item-active: #24292e;
  --color-filters-screen-text: #444d56;
  --color-filters-box-shadow: #d7dde2;
  --color-filters-box-active-bg: #eff1ff;
  --color-filters-box-active-border: #3e21ee;
  --color-filters-popup-import-file-border: #d1d5da;
  --color-filters-popup-import-dropdown-options: #d1d5da;
  --color-filters-popup-import-select-file-btn: #444d56;
  --color-filters-popup-import-help-text: #959da5;
  --color-table-basic-cell-separator: #d1d5da;
  --color-table-basic-header-text: #444d56;
  --color-table-basic-header-bg: #e1e4e8;
  --color-table-basic-content-text: #6a737d;
  --color-filter-header-separator: #e1e4e8;
  --color-filter-summary-title: #6a737d;
  --color-filter-add-trigger-text: #3e21ee;
  --color-filter-add-trigger-active-bg: #eff1ff;
  --color-filter-add-condition-form-label: #959da5;
  --color-filter-name: #6a737d;
  --color-filter-string: #444d56;
  --color-filter-condition-actions-separator: #aeb5e7;
  --color-filter-condition-actions-bg: #3e21ee;
  --color-filter-condition-bg: #eff1ff;
  --color-filter-combinator-active-bg: #eff1ff;
  --color-filter-combinator-border: #d1d5da;
  --color-filter-combinator-text: #6a737d;
  --color-filters-recommendations-attribute-label-bg: #7fb1dd;
  --color-filters-recommendations-attribute-add-text: #3e21ee;
  --color-filters-mapData-toggle-text: #3e21ee;
  --color-filters-mapData-summary-text: #6a737d;
  --color-filters-file-box-info-bg: #f6f8fa;
  --color-filters-condition-box-action-icon-crud: #fff;
  --color-filters-condition-box-action-icon-activate: #b6bcc2;
  --color-filters-condition-box-action-icon-deactivate: #6a737d;
  --color-policy-create-header-shadow: #d7dde2;
  --color-button-add-text: #3e21ee;
  --color-yellow: yellow;
  --color-attributes-secondary: #959da5;
  --color-attributes-set-title-name: #3e21ee;
  --color-attributes-set-list-title: #444d56;
  --color-attributes-set-rest-datasources-count: #f6f8fa;
  --color-attribute-card-label-sensitivity-high-text: #eb6818;
  --color-attribute-card-label-sensitivity-high-bg: #fff4ed;
  --color-attribute-card-label-sensitivity-medium-text: #976402;
  --color-attribute-card-label-sensitivity-medium-bg: #ffecc8;
  --color-attribute-card-label-sensitivity-low-text: #31a040;
  --color-attribute-card-label-sensitivity-low-bg: #edf7ef;

  --color-attribute-create-text-primary: #24292e;
  --color-attribute-create-subtitle: #444d56;
  --color-list-search-control-border: #d1d5da;
  --color-list-search-control-icon: #6a737d;
  --color-datasource-listing-category: #444d56;
  --color-datasource-category-datasource-type: #444d56;
  --color-datasource-type-box-border: #d1d5da;
  --color-datasource-type-box-border-hover: #3e21ee;

  --color-datasource-form-actions-separator: #b6bcc2;
  --color-conversations-card-public-label-bg: #e3f7fc;
  --color-conversations-card-public-label-text: #0f8aa5;
  --color-conversations-card-private-label-bg: #fff2fb;
  --color-conversations-card-private-label-text: #de5fbb;

  --color-recommendations-bg: #fbeecc;
  --color-recommendations-border: #c9ba9b;
  --color-recommendations-main: #573a08;
  --color-recommendations-secondary: #794b02;
  --color-access-public: #ea9f0e;
  --color-access-private: #afb4b9;

  --color-notification-success-bg: #f8fff9;
  --color-notification-success-border-color: #73d688;
  --color-notification-success-text-color: #277732;
  --color-notification-success-icon-color: #3bb354;
  --color-notification-error-bg: #fff7f7;
  --color-notification-error-border-color: #fc7d7d;
  --color-notification-error-text-color: #f04647;
  --color-icon-dismiss: #2f363d;

  --color-time-machine-icon-deleted: #c96f49;
  --color-time-machine-icon-new: #8973ca;
  --color-time-machine-badge-today: #3776ba;
}

[data-theme='theme2'] {
  --color-page-title: #24292e;
  --color-primary-nav-border: #d1d5da;
  --color-primary-nav-text: #24292e;

  --color-ellipsis-menu-border-default: #e1e4e8;
  --color-ellipsis-menu-border-focussed: #bac4ff;
  --color-ellipsis-menu-shadow-focussed: #d7dde2;
  --color-ellipsis-menu-popup-border: #bac4ff;
  --color-ellipsis-menu-item-hover-bg: #f5f7ff;
  --color-ellipsis-menu-item-hover-text: #3e21ee;
  --color-ellipsis-menu-item-text: #6a737d;
  --color-ellipsis-menu-icon-ellipsis: #24292e;

  --color-nav-hover-border: #bac4ff;
  --color-nav-hover-shadow: #d7dde2;

  --color-datasource-listing-category: #444d56;
  --color-datasource-category-datasource-type: #444d56;
  --color-datasource-type-box-border: #d1d5da;
  --color-datasource-type-box-border-hover: #3e21ee;
  --color-dropdown-icon: #959da5;

  --color-email-card-email: #959da5;
  --color-email-card-count-text: #6a737d;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/** Semantic UI overlay background **/
body.dimmed .dimmer {
  background-color: rgba(0, 0, 0, 0.4);
  transition: none;
}

/***************--------Primary button Styles----------**************/

button,
a {
  &.ui.button {
    box-shadow: none;
    text-transform: capitalize;
    .font-medium(1rem, 20px);

    .icon {
      .font-semiBold(1rem, 1);
    }
  }
}

button.ui.disabled,
a.button.ui.disabled {
  .button-basic();
  background-color: var(--color-bg-btn-disabled);
  border-color: var(--color-border-btn-disabled);
  color: var(--color-text-btn-disabled);
  opacity: unset !important;
}

button,
a {
  &.ui.primary.button {
    .icon {
      &:before {
        color: #fff;
      }
    }

    &:not(.inverted),
    &:hover:not(.inverted),
    &:active:not(.inverted),
    &:focus:not(.inverted) {
      .button-primary();
    }

    &.inverted,
    &.inverted:hover,
    &.inverted:active,
    &.inverted:focus {
      .button-primary-inverted();
    }

    &:not(.inverted):hover {
      background-color: var(--color-primary-background-hover);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }

    &:focus,
    &:active {
      box-shadow: none;
    }

    &:not(.icon) > .icon:not(.button):not(.dropdown) {
      margin-right: 8px;
      height: auto;
      opacity: 1;
      width: auto;
    }
  }

  &.ui.secondary.button {
    &,
    &:hover,
    &:active,
    &:focus {
      .button-secondary();
    }

    &:hover {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }

    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}

.simple {
  &.ui.toggle.checkbox input {
    &:checked ~ .box:before,
    &:checked ~ label:before,
    & ~ .box:before,
    & ~ label:before {
      width: 3.15rem;
      height: 1.43rem;
    }

    & ~ .box:before,
    & ~ label:before {
      background-color: var(--color-checkbox-default-bg) !important;
    }

    &:checked ~ .box:before,
    &:checked ~ label:before {
      background-color: var(--color-checkbox-bg) !important;
    }
  }

  &.ui.checkbox input {
    & ~ .box:after,
    & ~ label:after,
    &:checked ~ .box:after,
    &:checked ~ label:after {
      width: 1.15rem;
      height: 1.15rem;
      top: 2px;
    }

    & ~ .box:after,
    & ~ label:after {
      left: 0.2rem;
    }

    &:checked ~ .box:after,
    &:checked ~ label:after {
      left: 1.8rem;
    }
  }

  input + label {
    margin: 0;
  }
}

.ui.checkbox:not(.fitted, .simple) {
  label {
    &:before,
    &:after {
      width: 18px !important;
      height: 18px !important;
    }

    &:not(.radio) {
      border-radius: 4px !important;
    }

    &:before {
      border: 1.5px solid var(--color-checkbox-border) !important;
    }

    &:after {
      background: var(--color-checkbox-bg);
      color: var(--color-white) !important;
      font-size: 13px !important;
    }
  }
}

/***************--------Dropdown meny Styles----------**************/

a.ui.dropdown,
.ui.menu a.ui.dropdown {
  .menu {
    border: 1px solid var(--color-grey-2);
    box-shadow: 0px 0px 6px rgba(18, 20, 34, 0.2);
    border-radius: 8px;
    width: 140px;
    overflow: hidden;

    .item {
      .font-normal(0.94rem, 1.72rem);

      height: 32px;
      padding: 4px 13px !important;
      color: var(--color-grey-1);

      & + .item {
        border-top: 1px solid var(--color-grey-2);
      }

      .icon {
        margin-right: 9px;
        width: auto;
        height: auto;
        color: var(--color-grey-6);
      }
    }
  }
}

/***************--------Visibility Utilities----------**************/

/* Mobile */

@media only screen and (max-width: 767px) {
  [class*='mobile hidden'] {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*='mobile only']:not(.tablet) {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*='mobile only']:not(.computer) {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*='mobile only']:not([class*='large monitor']) {
    display: none !important;
  }
}

@media only screen and (min-width: 1920px) {
  [class*='mobile only']:not([class*='widescreen monitor']) {
    display: none !important;
  }
}

.ui.message {
  color: #fff;

  .header {
    color: #fff !important;
  }

  &.positive {
    background-color: var(--color-green-1) !important;
    box-shadow: none !important;
    color: #fff !important;
  }

  &.negative {
    background-color: var(--color-red-1) !important;
    box-shadow: none !important;
    color: #fff !important;
  }
}

#app {
  position: relative;
  overflow: auto;

  main {
    display: flex;
    height: 100vh;
    padding-top: var(--navbar-height);
  }
}

/* Page Context */

.screen-title {
  color: var(--color-grey-1);
  display: flex;
  align-items: center;

  p {
    .font-bold(1.43rem, 1.2);
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-unset {
  overflow: unset !important;
}

.list-unstyled {
  list-style-type: none;
  padding: 0 !important;
}

.empty-list {
  color: var(--color-red-1);
  .font-medium(1.1rem, 1.4);
}

div.list-search {
  max-width: calc(360px + 2rem);

  .search {
    .input {
      width: 100%;

      input {
        .font-normal(0.93rem, 1);
        border: 1px solid var(--color-grey-5);
        border-radius: 8px;
        color: var(--color-grey-1);
        height: 36px;

        &:focus {
          border-color: var(--color-blue-2);
        }
      }

      .icon {
        opacity: 1;

        &:before {
          color: var(--color-grey-6);
          font-size: 1.2rem;
        }
      }
    }
  }
}

body {
  // radio
  .ui.radio.checkbox:not(.simple) {
    label:after {
      background-color: var(--color-dodger-blue) !important;
    }
  }

  // togglers
  .ui.fitted.toggle.checkbox:not(.simple) {
    min-height: unset;
    height: 16px;
    width: 36px;
    padding: 0;

    label {
      padding: 0;
    }

    label:after {
      width: 13px;
      height: 12px;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      background-color: var(--color-cadet-blue);
    }

    input {
      width: 100%;
      height: 100%;
    }

    input:checked ~ label:before,
    input:focus ~ label:before,
    input:focus:checked ~ label:before {
      background-color: var(--color-grey-9) !important;
    }

    input:checked ~ label:after {
      left: 21px !important;
      background: var(--color-blue-2);
    }

    label,
    label:before {
      height: 100%;
      width: 100%;
      min-height: unset;
    }

    label:before {
      border: 1px solid var(--color-grey-11) !important;
    }
  }
}

.h100p {
  height: 100%;
}

.ui.grid.pagination-controls {
  display: flex;

  .ui.pagination.menu {
    .font-bold(1rem, 1);
    padding: 0;
    border: 0;
    box-shadow: none;

    .item.icon .icon {
      .font-normal(1.3rem, 1);
      color: var(--color-panigation-text);
      opacity: 1;
    }

    .item:focus,
    .item:active {
      outline: 0;
    }

    .item + .item&:not(.next-item) {
      margin-left: 16px;
    }

    .next-item {
      margin-left: 9.5px;
    }

    .prev-item {
      margin-right: 9.5px;
    }

    .item {
      padding: 0;
      justify-content: center;
      min-width: unset;
      padding-top: 0.7em;
      padding-bottom: 0.7em;
      color: var(--color-panigation-text);
      .font-semiBold(1rem, 20px);

      &.active {
        background-color: var(--color-panigation-text-active-bg);
        color: var(--color-panigation-text-active);
      }

      &:hover {
        opacity: 0.8;
      }

      &:before {
        display: none;
      }

      &:not(.next-item):not(.prev-item) {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }
}

.flex-grow-1 {
  flex-grow: 1;
}

.widget-title {
  .font-semiBold(0.93rem, 1);
  color: var(--color-ebony-clay);
}

.widget-title-text {
  .font-semiBold(1rem, 1rem);
  text-transform: capitalize;
  color: var(--color-ebony-clay);
  display: flex;
}

.ui.fluid.dropdown.selection {
  border: 1px solid var(--color-inputs-border-default);
  border-radius: 4px;
  color: var(--color-non-click-text);
  display: flex;
  padding-right: 1em;
  .font-normal(1rem, 20px);

  .default.text {
    color: var(--color-dropdown-placeholder);
  }

  .text {
    flex-grow: 1;
  }

  .icon {
    color: var(--color-input-icon);
  }

  &:focus,
  &.active {
    &,
    .menu,
    &.dropdown:hover,
    &.dropdown:hover .menu {
      border-color: var(--color-inputs-focussed-border);
    }
  }
}

.form-control {
  label {
    display: block;
    .font-normal(0.86rem, 1.5em);
    text-transform: capitalize;
    color: var(--color-form-field-label);
    letter-spacing: 0.4px;
    margin-bottom: 5px;
  }

  input {
    padding: 0.72em 0.86em;
  }

  input,
  textarea,
  .value {
    border: 1px solid var(--color-inputs-border-default);
    border-radius: 4px;
    background: var(--color-white);
    width: 100%;
    outline: none;
    color: var(--color-non-click-text);
    .font-normal(1rem, 1.43em);

    &:focus {
      border-color: var(--color-inputs-focussed-border);
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.45;
    }
  }

  &.required {
    label:after {
      color: var(--color-form-field-error);
      content: ' *';
    }
  }

  &.error {
    label {
      color: var(--color-form-field-error);
    }

    input,
    textarea,
    .ui.dropdown,
    .value {
      border-color: var(--color-form-field-error) !important;
    }
  }
}

.show-required::after {
  margin: -0.2em 0 0 0.2em;
  content: '*';
  color: #db2828;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    right: 0;
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }
}

.uppercase {
  text-transform: uppercase;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.clickable {
  cursor: pointer;

  &:hover {
    color: var(--color-purple);
  }
}

/** Page layout **/
.filters-row {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1.5rem;
  align-items: center;
  justify-content: flex-end;

  .divider {
    height: 2.8rem;
  }
}

.box-styles {
  background: var(--color-white);
  border: 1px solid var(--color-mystic);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem;
  flex-grow: 1;
  flex-basis: 0;
}

.text-capitalize {
  text-transform: capitalize !important;
  * {
    text-transform: capitalize !important;
  }
}

.table-wrapper {
  position: relative;
}
.btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  border: none;
  box-shadow: none;
  background: none;

  &:hover {
    opacity: 1;
  }

  &.disabled,
  &:disabled {
    color: var(--color-alerts-action-text);
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 1;
  }
  &.disabled {
    background-color: var(--color-mystic);
  }

  &.primary {
    color: var(--color-white);
    background-color: var(--color-bg-btn-primary);
  }
}

.btn-text {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  border: none;
  box-shadow: none;
  background: none;
  padding: 0;

  &:hover {
    opacity: 0.7;
  }

  &.disabled,
  &:disabled {
    background-color: var(--color-mystic);
    color: var(--color-alerts-action-text);
    cursor: not-allowed;
    pointer-events: all !important;
  }
}

.dashboard-container {
  display: flex;
  flex-flow: column nowrap;
  padding: 1.6rem;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  border: 1px solid var(--color-mystic-2);
  background-color: var(--color-white);
  flex-grow: 1;
  overflow: auto;
}

.dashboard-container-title {
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.43em;
  margin-bottom: 1.4em;
  align-self: flex-start;
  color: var(--color-shark);

  &.centered {
    justify-content: center;
    align-self: center;
  }

  &.print {
    text-transform: uppercase;
  }
}

/** Structured data **/
.page.structured-data,
.page.mixed-ds {
  background: none;
  padding: 1.8rem;

  .section {
    margin-bottom: 1rem;
  }

  .dashboard-content {
    z-index: auto;
  }

  .sub-page {
    padding: 1.5rem;

    .section.tables {
      .tables-container {
        .action-buttons {
          .actions-popup-trigger {
            padding: 0;
          }
        }
      }
    }
  }

  .sub-page {
    padding: 1.5rem;
  }

  .dashboard-content,
  .section-content,
  .section,
  .sub-page {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;

    .page-header {
      flex-grow: 0;
      margin-bottom: 2rem;
    }
  }

  .sub-page.databases {
    .databases-columns {
      display: flex;
      flex-flow: row nowrap;

      .whats-new {
        margin-left: 1rem;
        width: 30%;
      }

      .databases-list {
        flex-grow: 1;
        width: 70%;

        .content-wrapper {
          margin-top: 2rem;
          .search-field {
            width: 25%;
          }
        }
      }
    }
  }

  .header-with-icon {
    display: flex;
    align-items: center;

    svg {
      padding-top: 0.2rem;
    }
  }

  .mock {
    width: 100%;

    &.container-view {
      background-color: var(--color-white);
      border-radius: 6px;
      border: 1px solid var(--color-structured-data-border);
    }
  }

  .section-above-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }
}

.structured-data,
.mixed-ds {
  .dashboard-container {
    background-color: var(--color-white);
    border-radius: 6px;
    border: 1px solid var(--color-structured-data-border);
    padding: 1rem;
  }

  .content-wrapper {
    border: none;

    &.entity-details {
      margin: 0;
    }
  }

  .ui.header.page-header {
    display: flex;
    align-items: center;

    svg {
      height: 1.5rem;
      margin-right: 0;
    }
  }

  .nav-tabs {
    margin: 0;
  }

  .summary {
    .structured-summary-header {
      font-size: 1.5rem;
      font-weight: 400;
      color: var(--color-text-secondary);
      .associated-labels {
        display: flex;
        font-size: 14px;
        margin-top: 12px;
        align-items: center;
        gap: 12px;
        .selected-labels {
          margin-top: 0;
        }
      }
    }

    .db-instance-name {
      font-weight: 700;
      color: var(--color-text-primary);
    }
  }

  .table-details {
    margin-top: 2rem;

    .dashboard-container {
      display: flex;
      flex-flow: row nowrap;
      padding: 0;

      .content-side {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        width: calc(100% - 34rem);
        max-width: unset;

        .segment {
          padding: 1rem 1.8rem;
          border-bottom: 1px solid var(--color-page-content-border);

          &.table-overview {
            flex-grow: 1;
            border-bottom: none;
          }

          &.header {
            display: block;
            padding-bottom: 0;

            .table-summary-wrapper {
              .table-summary-header {
                .actions-popup-trigger {
                  padding: 0;
                  background-color: unset;
                }
              }
            }
            .nav-tabs {
              margin-bottom: -1px;
            }
          }
        }
      }

      .page-header {
        margin-top: 0;
        margin-bottom: 1rem;
      }

      .table-summary {
        justify-content: flex-start;
        margin-left: 0;
      }

      .table-sample-container {
        margin-top: 2rem;
      }
    }
  }

  .database-details {
    .widgets-grid {
      flex-grow: 1;
      display: grid;
      gap: 1rem;
      grid-template-areas:
        'widget-tables'
        'widget-columns'
        'widget-entities'
        'widget-alerts';

      .widget-tables {
        grid-area: widget-tables;
      }
      .widget-columns {
        grid-area: widget-columns;
      }
      .widget-entities {
        grid-area: widget-entities;
      }
      .widget-alerts {
        grid-area: widget-alerts;
      }

      @media screen and (min-width: 800px) {
        & {
          grid-template-columns: 1fr 1fr;
          grid-template-areas:
            'widget-tables widget-columns'
            'widget-entities widget-alerts';
        }
      }
      @media screen and (min-width: 1200px) {
        & {
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas:
            'widget-tables widget-columns widget-entities'
            'widget-tables widget-columns widget-alerts';
        }
      }
    }
    .nav-tabs {
      margin-bottom: 1.8rem;
    }
    .ui.basic.table.data-list-table {
      .profile-type {
        min-width: 10rem;
        padding: 1rem 0.5rem;
      }
    }
  }
}

.structured-data {
  .sub-page {
    padding: 1.5rem;
  }

  .grid-container {
    display: grid;
    gap: 1rem;
    grid-auto-columns: 100%;
    grid-template-columns: 1fr;
    grid-template-areas:
      'databases'
      'scan_status'
      'summary'
      'attributes'
      'entities'
      'scanned_data'
      'alerts';
    grid-auto-rows: auto;

    .chart-label-wrapper {
      justify-content: center;
      display: flex;
    }

    .legend {
      display: flex;
      justify-content: center;
      gap: 1.1428rem;
    }

    .chart-label {
      display: flex;
      align-items: center;
      gap: 6px;
      .label-line {
        width: 2px;
        height: 1.1428rem;
      }
      &.show-cursor {
        cursor: pointer;
      }

      .label-content {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .dashboard-container {
      display: flex;
      border-radius: 6px;
      box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.16), 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
      border: 0;

      & > * {
        flex-grow: 1;
        width: 100%;
      }
    }

    .widget_db {
      grid-area: databases;

      .status-widget-compact,
      .databases-and-policies {
        flex-grow: unset;
      }
    }
    .widget_attributes {
      grid-area: attributes;
      .datasource-attributes-info-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        .attributes {
          display: flex;
          flex-direction: row;
          gap: 1rem;

          .legend {
            display: flex;
            flex-direction: column;
            gap: 0.28rem;
            margin: 0;
            .item {
              line-height: unset;
              margin: 0;
            }
          }
        }
      }
    }

    .widget_scan_status {
      grid-area: scan_status;
    }

    .widget-policy-status {
      .header-wrapper {
        display: flex;
        justify-content: space-between;
        .policy-link {
          display: flex;
          align-items: center;
          gap: 4px;
          .text-lb-primary {
            color: var(--lb-color-purple-500);
            margin-bottom: 0;
          }
        }
      }
      .policy-items {
        display: flex;
        flex-direction: column;
        gap: 6px;
        flex: 1;
      }
      .policy-item {
        background-color: rgba(0, 21, 41, 0.02);
        display: flex;
        padding: 8px 10px;
        justify-content: space-between;
        gap: 0.571rem;
        border-radius: 6px;
        .count {
          text-align: right;
        }
        .at-risk-text {
          display: flex;
          align-items: center;
          gap: 2px;
          text-wrap: nowrap;
        }

        .description {
          text-wrap: wrap;
        }
      }
    }

    .widget_entities {
      grid-area: entities;
      align-items: center;
      .entities-summary {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        height: 100%;
        flex-grow: 1;
        width: 100%;
        grid-area: entities-summary;
        position: relative;
        &.widget {
          display: flex;
          flex-direction: column;
          justify-content: unset;
          gap: 0.571rem;
          flex: 1;
        }
        .summary-container {
          display: flex;
          flex: 1;
          flex-direction: column;
          gap: 0.571rem;
          background-color: initial;
          .chart-pie-wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }

    .widget_alerts {
      grid-area: alerts;
      .alerts-summary-widget {
        .chart-bar-simple {
          margin-bottom: 0;
          background-color: var(--color-white);
        }
        .carousel-wrapper {
          background-color: var(--color-white);
          .carousel-button-group {
            top: 0;
          }
        }

        .alerts-slide .item {
          border: none;
        }

        .critical-alerts {
          margin-top: 32px;
        }

        .line-divider {
          margin-bottom: 24px;
          width: calc(100% + 32px);
          left: -16px;
          position: relative;
          height: 2px;
          background-color: var(--page-backgroud);
        }
      }
    }

    .widget_scanned_data {
      grid-area: scanned_data;
      position: relative;
      min-width: calc(50% - 12px);
      .scanned-data-widgets {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }

    .widget_struct_summary {
      grid-area: summary;

      &.dashboard-container {
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .structured-data .grid-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'databases  widget-policy-status'
      'summary alerts'
      'attributes alerts'
      'entities entities'
      'scanned_data scanned_data';
  }
}

@media screen and (min-width: 1280px) and (max-width: 1779px) {
  .structured-data .grid-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'databases summary alerts'
      'attributes entities alerts'
      'scanned_data scanned_data widget-policy-status';
  }
}

@media screen and (min-width: 1780px) {
  .structured-data .grid-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'databases summary attributes alerts'
      'entities scanned_data scanned_data alerts'
      'widget-policy-status scan_status . .';
  }
}

.tags-dropdown {
  .ui.multiple.dropdown {
    flex-wrap: wrap;
    padding-right: 1em;
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 2.71428571em;

    & > .label {
      background: var(--color-dropdown-tag-bg);
      box-shadow: none;
      // border: 1px solid var(--color-dropdown-tag-border);
      border-radius: 3px;
      padding: 4px 8px;
      color: var(--color-dropdown-tag-text);
      font-size: 0.86rem;
      line-height: 1.25em;
      font-weight: normal;
    }

    .chevron.icon {
      margin-top: 0.45238095em;
    }
  }

  .ui.fluid.dropdown.selection .delete.icon {
    margin-left: 4px;
    color: var(--color-dropdown-tag-remove);
    font-size: 0.86rem;
    opacity: 1;
  }

  input {
    flex-grow: 1;
  }
}

.content-wrapper {
  background-color: var(--color-white);
  padding: 1.8rem;
  border: 1px solid var(--color-mystic);
  border-radius: 8px;
  flex-grow: 1;

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.columns-table {
  margin-top: 1rem;
  .name-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    svg {
      min-width: 1.5rem;
      margin-right: 0.5rem;
    }

    .caret {
      margin-right: 0.5rem;
    }

    .title {
      margin-right: 1rem;
    }
  }

  .checkbox-column {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .identifier-wrapper {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0 0.5rem 3rem;

    .status-container {
      display: flex;
      align-items: center;
      width: 4rem;
      margin-left: 1rem;

      .changes-status {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 1rem;
        min-width: 3rem;
        font-size: 0.92rem;
        font-weight: 600;
        border: none;
        box-shadow: none;
        background: none;
      }

      &.updated .changes-status {
        cursor: pointer;
        color: var(--color-primary-light);

        &:hover {
          opacity: 0.7;
        }
      }
      &.pending .changes-status {
        color: var(--color-primary-light);
      }
      &.saved {
        color: var(--color-status-ok);

        svg {
          min-width: 1.4rem;
          margin-right: 0.2rem;
        }
      }
    }
  }

  .identifiers-trigger-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .identifier-box {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-table-not-selected);
    border-radius: 3px;
    color: var(--color-table-not-selected);
    font-size: 0.92rem;
    text-transform: capitalize;
    background-color: var(--color-white);
    width: 13rem;
    padding: 0.4rem 0.6rem;
    overflow: hidden;
    cursor: pointer;
    i.chevron {
      margin-bottom: 0.3rem;
    }
    i {
      color: var(--color-table-not-selected);
    }

    &.identifier-exists {
      border: 1px solid var(--color-table-customer);
      color: var(--color-table-customer);

      i.times {
        margin-bottom: 0.35rem;
      }
    }
    &.maybe-pii {
      justify-content: space-between;
      border: 1px solid #fc9d03;
      color: #1b2229;
      i.times {
        margin-bottom: 0.35rem;
      }

      .popup-info-trigger {
        margin-left: 1rem;
        margin-right: 0.25rem;
      }
    }

    &.opened {
      border: 1px solid var(--color-table-opened);
      color: var(--color-table-opened);
    }

    .confidence {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.79rem;
      border-radius: 10px;
      width: 3rem;
      height: 1.3rem;
      background-color: var(--color-structured-data-bg-confidence);
    }

    .truncate {
      flex: 1;
    }
  }

  .select-manually,
  .btn.confirm-pii {
    color: #3e21ee;
    font-weight: 500;
    font-size: 0.92rem;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .btn.confirm-pii {
    margin-left: 0.5rem;
  }

  .select-manually {
    margin-left: 1rem;
  }
}

.meta-container {
  display: flex;
  flex-flow: row nowrap;
  padding: 1.8rem 0 1.8rem 15rem;
  background-color: var(--color-structured-data-bg-table-meta-data);

  .checkboxes-container {
    display: flex;
    flex-flow: column nowrap;

    .checkbox {
      &:not(:last-of-type) {
        margin-bottom: 1.8rem;
      }
    }
  }

  .sample-container {
    margin-left: 4rem;

    .title {
      font-size: 0.92rem;
      font-weight: 500;
    }
  }
}

.dashboard {
  background: var(--color-page-bg);

  .section-above-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    .breadcrumbs {
      flex-grow: 1;
    }
  }

  .dashboard-content {
    border-radius: 8px;
  }
}
.divider-vertical {
  border: 1px solid var(--color-mystic);
}
.empty-state-text {
  .font-medium(0.86rem, 1.7em);
  color: #6a737d;
}
.empty-state-title {
  .font-semiBold(1rem, 1.7em);
  color: #6a737d;
}
.table_controls {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.divider {
  border-left: 1px solid var(--color-mystic);
  margin: 0 1.1rem;
}
.pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}

.subpage-attributes {
  .attributes {
    min-height: 50vh;
  }
}

.common-separator {
  background-color: var(--color-border-separator);
  &.vertical {
    margin: 0 1rem;
    height: 100%;
    width: 1px;
  }
  &.horizontal {
    margin: 1rem 0;
    height: 1px;
    width: 100%;
  }
}
@media print {
  body,
  html,
  #app {
    height: 100% !important;
    overflow: visible;

    header.lb-navbar {
      display: none;
    }

    .page-break {
      page-break-before: always;
    }
  }
}

.content-whitespace-prewrap {
  white-space: pre-wrap;
}

.UserAvatar .UserAvatar--inner {
  text-transform: uppercase;
  cursor: default;
  color: var(--color-black);
}

.text-transform-initial {
  text-transform: initial !important;
}

.page-change-tabs-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  .ui.dropdown.custom-dropdown {
    width: 20rem;
  }
}
.field-is-required span:after {
  margin: -0.2em 0 0 0.2em;
  content: '*';
  color: #db2828;
}

.column-expanded-details {
  border-left: 3px solid #bac4ff;
  padding: 1rem;
  background-color: #f5f7ff;
  .field-info {
    display: flex;
    .ui.label.more-items {
      border-radius: 16px;
      font-weight: 1.3em;
    }

    .field-value {
      .font-medium(0.86rem, 1.5em);
      color: var(--color-non-click-text);
    }

    label {
      .font-normal(0.86rem, 1.5em);
      color: var(--color-river-bed);
      width: 16%;
    }

    & + .field-info {
      margin-top: 1.25rem;
    }
  }
}
.list-expandable {
  .field-expandable {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .icons-info {
      display: flex;
      align-items: center;
      width: 2rem;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 0.5rem;

      svg {
        flex-shrink: 0;
      }
    }
  }
}
.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.textarea-whitespece-pre-wrap {
  white-space: pre-wrap !important;
}

.caret-on-hover {
  position: relative;

  &:hover {
    color: var(--color-purple);

    &::after {
      position: absolute;
      margin-left: 0.25rem;
      font-size: 1.75rem;
      content: '\203A';
    }
  }
}

.accessible-container {
  padding: 0.5rem;
  .item {
    border-left: 1px solid #e1e4e8;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    width: 80px;

    &:not(:first-of-type) {
      margin-left: 1rem;
    }

    .count {
      margin-bottom: -0.25rem;
    }
  }
}

.has-warning {
  color: var(--color-red-1) !important;
}

.field-radio-options {
  display: flex;
  align-items: center;
  .radio-custom {
    margin-right: 5rem;
  }
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-center {
  display: flex;
  align-items: center;
}

.jc-center {
  display: flex;
  justify-content: center;
}

.jc-end {
  display: flex;
  justify-content: flex-end;
}

.jc-start {
  display: flex;
  justify-content: flex-start;
}

.jc-space-between {
  display: flex;
  justify-content: space-between;
}

.w-full {
  width: 100%;
}
