@import '../../assets/mixins.less';
div.ui.popup.visible.popup-info {
  padding: 0.2rem 0.6rem;
  box-shadow: none;
  background-color: #2f363d;
  color: var(--color-white);
  border-radius: 6px;
  font-size: 0.86rem;
  font-weight: 500;
  z-index: 9999;

  .content {
    .font-medium(0.86rem, 1.5em);
  }
  .menu.visible {
    margin-left: -0.7rem;
    margin-top: 1rem;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    border: 1px solid #bac4ff;
  }

  &::before {
    background-color: #2f363d;
  }
}
