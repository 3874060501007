.chart-bar-simple {
  background-color: #f6f8fa;
  // padding-top: 1rem;
  .responsive-container {
    min-width: 100%;
  }
  .recharts-responsive-container {
    margin-left: -1rem; // TODO: find a way not to use negative margin
  }

  .recharts-cartesian-axis-tick-line {
    display: none;
  }

  .axis-label {
    color: var(--color-text-secondary);
    font-size: 0.79rem;
    font-weight: 400;
    fill: #001529cc;
  }
}
