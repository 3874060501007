/* --------------------------------
Typography
-------------------------------- */
/* inter-100 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: local(''), url('./assets/fonts/Inter/inter-v7-latin-ext_latin-100.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Inter/inter-v7-latin-ext_latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-200 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: local(''), url('./assets/fonts/Inter/inter-v7-latin-ext_latin-200.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Inter/inter-v7-latin-ext_latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-300 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('./assets/fonts/Inter/inter-v7-latin-ext_latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Inter/inter-v7-latin-ext_latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-regular - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./assets/fonts/Inter/inter-v7-latin-ext_latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Inter/inter-v7-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-500 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('./assets/fonts/Inter/inter-v7-latin-ext_latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Inter/inter-v7-latin-ext_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-600 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('./assets/fonts/Inter/inter-v7-latin-ext_latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Inter/inter-v7-latin-ext_latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-700 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('./assets/fonts/Inter/inter-v7-latin-ext_latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Inter/inter-v7-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-800 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: local(''), url('./assets/fonts/Inter/inter-v7-latin-ext_latin-800.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Inter/inter-v7-latin-ext_latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-900 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: local(''), url('./assets/fonts/Inter/inter-v7-latin-ext_latin-900.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Inter/inter-v7-latin-ext_latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(./assets/fonts/archivo/archivo-latin-ext-variable.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(./assets/fonts/archivo/archivo-latin-variable.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
    U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

// Source Link: https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap&text=%26
@font-face {
  font-family: 'Ampersand';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-italic-100.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-italic-300.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-italic-400.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-italic-500.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-italic-700.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-italic-900.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-normal-100.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-normal-300.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-normal-400.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-normal-500.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-normal-700.woff2) format('woff2');
}
@font-face {
  font-family: 'Ampersand';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./assets/fonts/roboto-ampersand/roboto-ampersand-normal-900.woff2) format('woff2');
}

:root {
  --font-primary: 'Ampersand', 'Archivo', -apple-system, BlinkMacSystemFont, 'Inter', sans-serif;
  --font-report: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;

  /* set base values */
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;

  /* type scale */
  --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
  --text-sm: calc(1em / var(--text-scale-ratio));
  --text-md: calc(1em * var(--text-scale-ratio));
  --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-xxl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio)
  );
  --text-xxxl: calc(
    1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio) * var(--text-scale-ratio)
  );

  /* line-height */
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

@include breakpoint(md) {
  :root {
    --text-base-size: 1.25em;
    --text-scale-ratio: 1.25;
  }
}

html *:not(i, span[style*='font-family'], .report *) {
  font-family: var(--font-primary) !important;
}
.report * {
  font-family: var(--font-report) !important;
}

@media only screen and (max-width: 1440px) {
  :root {
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  :root {
    font-size: 16px !important;
  }
}
@media only screen and (min-width: 1921px) and (max-width: 2560px) {
  :root {
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 2561px) {
  :root {
    font-size: 22px !important;
  }
}
body {
  font-size: var(--text-base-size);
  color: var(--color-shark);
}
h1,
h2,
h3,
h4,
form legend {
  color: var(--color-text-heading);
  margin-bottom: var(--space-xxs);
  line-height: var(--heading-line-height);
}

/* text size */
.text--xxxl {
  font-size: var(--text-xxxl);
}

h1,
.text--xxl {
  font-size: var(--text-xxl);
}

h2,
.text--xl {
  font-size: var(--text-xl);
}

h3,
.text--lg {
  font-size: var(--text-lg);
}

h4,
.text--md {
  font-size: var(--text-md);
}

.text--sm,
small {
  font-size: var(--text-sm);
}

.text--xs {
  font-size: var(--text-xs);
}
p {
  line-height: var(--body-line-height);
}

a {
  color: var(--color-link);
}

b,
strong {
  font-weight: bold;
}

.text-container {
  h2,
  h3,
  h4 {
    margin-top: var(--space-sm);
  }

  ul,
  ol,
  p {
    margin-bottom: var(--space-md);
  }

  ul,
  ol {
    list-style-position: outside;
    padding-left: 24px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul li,
  ol li {
    line-height: var(--body-line-height);
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }
}

/* utility classes */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.sidebar {
  overflow: hidden;
  border-right: 1px solid #d8dbe9;
}

.ui.segment {
  &.main-content {
    margin-left: 240px;
    margin-top: 56px;

    .segment {
      border: 0;
      box-shadow: none;
    }
  }
}

.sidebar.collapsed {
  & + .pusher {
    .ui.segment {
      &.main-content {
        margin-left: 60px;
      }
    }
  }
}

.theme-overrides {
  .ant-menu-item-selected .ant-menu-title-content {
    font-weight: 600 !important;
  }

  &.light-theme {
    background-color: #e8eaec;
  }

  &.dark-theme {
    background-color: #191919;
    .ant-menu-sub {
      background-color: #202020 !important;
    }

    .topNavContainer {
      background-color: #202020 !important;

      .ant-menu {
        background-color: transparent !important;
      }

      .ant-menu-item,
      .ant-menu-submenu-title {
        color: #fff !important;
        // svg path stoke color
        svg {
          path {
            stroke: #fff !important;
          }
        }
      }

      .ant-menu-item-selected {
        background-color: #5137f029 !important;
        color: #7864f3 !important;
      }

      .ant-btn-default {
        background-color: transparent !important;
      }

      svg {
        path {
          stroke: #fff !important;
        }
      }

      .user-name {
        color: #fff !important;
      }
    }

    .date-range-container {
      .btn {
        border: 1px solid #ffffff29 !important;
      }

      .btn.active {
        background-color: #7864f3 !important;
      }
    }

    .data-sources-summary {
      svg {
        path {
          stroke: #fefefe !important;
        }
      }
    }

    .text-lb-primary,
    .text-lb-secondary,
    .text-base,
    .text-lb-placeholder {
      color: #fff !important;
    }

    .classifications-or-labels.widget-grouped .legend .item {
      color: #fff !important;
    }

    .dashboard-page .widgets {
      .policy-status .header-wrapper .policy-link .text-lb-primary {
        color: #7864f3;
      }
      .alerts-summary .critical-alerts {
        .pagination {
          color: #8b959e !important;
        }

        .line-divider {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }

      .card-sub-item {
        background-color: #ffffff0a !important;
      }
    }

    .axis-label,
    .recharts-cartesian-axis-tick tspan {
      fill: #ffffff80 !important;
    }

    .recharts-label tspan {
      fill: #ffffff;
    }

    .alerts-slide .assign-btn .text-lb-primary {
      color: #7864f3 !important;
    }

    .recharts-cartesian-grid-horizontal {
      line {
        stroke: #ffffff14 !important;
      }
    }

    .data-sources-summary {
      .main-totals,
      .total {
        background: rgba(255, 255, 255, 0.04) !important ;

        .summary-item .at-risk {
          color: #f25e13 !important;
        }
      }
    }

    .chart-pie-wrapper {
      svg {
        path {
          stroke: none !important;
        }
      }
    }

    .privacy-ops-widget .widget-section {
      border-bottom: 1px solid #ffffff14;

      .progress {
        background-color: #4e74ff !important;
      }
    }

    .policy-status-widget .policy-link {
      .text-lb-primary {
        color: #7864f3 !important;
      }

      svg {
        polyline {
          stroke: #7864f3 !important;
        }
      }
    }
  }
}

// colors
@page-background: #f6f8fa;
@card-background: #fff;

@dark-page-background: #191919;
@dark-card-background: #202020;

@purple: #3e21ee;
@danger: #f04647;

.light-theme {
  --page-backgroud: @page-background;
  --card-background: @card-background;
  --color-purple: @purple;
  --color-danger: @danger;
}
.dark-theme {
  --page-backgroud: @dark-page-background;
  --card-background: @dark-card-background;
  --color-purple: @purple;
  --color-danger: @danger;
}

.ant-input-outlined:focus,
.ant-input-outlined:hover,
:where(.css-dev-only-do-not-override-1jg35ys).ant-input-outlined:focus-within {
  border-color: var(--lb-color-purple-500);
}
.ant-input-outlined:focus-within {
  box-shadow: 0 0 0 var(--size-2px) var(--lb-color-input-shadow);
}
