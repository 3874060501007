.ui.dropdown.dropdown-add-item {
  padding: 0.6rem 1rem;
  border: 1px solid #d1d5da;
  border-radius: 6px;
  width: 20rem;
  &.multiple.search.ui > input.search {
    margin-bottom: 0;
  }
  & > .text {
    color: var(--color-primary-light);
    font-size: 0.86rem;
    font-weight: 600;
  }
  .trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 20rem;

    .icon-box {
      margin-right: 0.5rem;
    }
  }
  .visible.menu.transition {
    margin-top: 0.5rem;
    width: 20rem;
  }

  .item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .option-icon {
      line-height: 0;
      display: inline-flex;
      margin-right: 1rem;
      border-radius: 50%;
      background-color: rgba(140, 189, 239, 0.2);
      padding: 0.4rem;
    }
  }
  &::before {
    display: none;
  }
}
