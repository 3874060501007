.steps-bar {
  display: flex;
  justify-content: flex-start;

  .steps-wrapper {
    flex-grow: 1;
    padding-right: 5rem;

    @media only screen and (max-width: 767px) {
      padding-right: 0;
    }
  }

  .row.circles {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 2.5rem;

    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 1px solid var(--color-ds-register-steps-inactive);
      font-size: 0.86rem;
      color: var(--color-ds-register-steps-inactive);

      &.active:not(.full) {
        background: var(--color-ds-register-steps-active-bg);
        color: var(--color-white);
      }
    }

    .connector {
      flex-grow: 1;
      height: 2px;
      background-color: var(--color-ds-register-steps-connector);
      margin: 0 0.25rem;
    }
  }

  .row.text {
    margin-top: 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.8rem;
    padding-left: 0.8rem;

    .text {
      font-size: 0.86rem;
      color: var(--color-ds-register-steps-inactive);

      &.active:not(.full) {
        color: var(--color-ds-register-steps-active-text);
        font-weight: 600;
      }
    }
  }
}
